import React, { useEffect, useState, useContext } from "react";
import Opportunities from "../../layouts/OpportunitiesList";
import "./FindJobs.css";
import OpportunityCollectionCard from "../../components/Cards/OpportunityCollectionCard";
import { Row, Col, } from 'antd';
import { Link } from "react-router-dom";
import Collections from "./Collections";


const FindJobs = () => {

  const [viewList, setViewList] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");


  const changeCategory = (category) => {
    setSelectedCategory(category);
    setViewList(false);
    console.log(selectedCategory);
  }

  const goBack = () => {
    setViewList(true);
  }

  return (
    <div className="container" >
      {/* Title Section */}
      < div className="title-section" >
        <div className="title-content">
          <h1 className="title">Opportunities</h1>
          <p className="blurb">
            Welcome to our Opportunities Board! Explore a diverse range of prospects including job postings, potential networking contacts, prospective companies, and upcoming networking events. Start shaping your future by finding your next big opportunity today.
          </p>
        </div>
      </div >

      {/* Job (Opportunity) Collections*/}
      {viewList && (
        <div>
          <Row gutter={[16, 16]}>
            {Object.keys(Collections).map(key => (
              <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                <div key={key}>
                  <OpportunityCollectionCard opportunity={Collections[key]} changeCategory={changeCategory} />
                </div>
              </Col>
            ))}
          </Row>
        </div >
      )}


      {/* Job Postings List */}
      {!viewList && (
        <div className="job-list-section">
          <Link to="#" onClick={goBack} style={{ position: 'relative', fontSize: '1em' }}>&#8592; Back To Collections</Link>
          <Opportunities category={selectedCategory} />
        </div>
      )}

      {/* Job Postings List */}
      <div className="job-list-section">
        {/* <Opportunities /> */}
      </div >




    </div >

  );
};

export default FindJobs;
