import React from "react";
import { Card, Row, Col, Tag, Statistic } from "antd";
import "../Card.css";

const JobPostingStats = ({ job, numberOfApplicants, numberOfShortlisted, numberOfInterviewScheduled, numberOfRejected, numberOfHired }) => {
    return (
        <Card
            bordered={true}
            className="posting-card"
            style={{
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                padding: "20px",
                textAlign: "left", // Override center alignment
            }}
        >
            {/* First Row: Date Posted and Status */}
            <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
                <Col span={12}>
                    <div style={{ display: "flex", alignItems: "center", lineHeight: "1.5" }}>
                        <strong style={{ marginRight: "8px" }}>Date Posted:</strong>
                        <span>{job.formattedPostDate || "N/A"}</span>
                    </div>
                </Col>
                <Col span={12}>
                    <div style={{ display: "flex", alignItems: "center", lineHeight: "1.5" }}>
                        <strong style={{ marginRight: "8px" }}>Status:</strong>
                        <Tag
                            color={job.status === "Active" ? "green" : "red"}
                            style={{
                                fontSize: "14px",
                                padding: "0 10px", // Adjust padding to match text height
                                lineHeight: "1.5", // Ensure consistent line height
                            }}
                        >
                            {job.internalPostingStatus || "Unknown"}
                        </Tag>
                    </div>
                </Col>
            </Row>

            {/* Second Row: Kanban-style Stats */}
            <Row gutter={[29, 29]} justify="space-between" style={{ marginBottom: "0px" }}>
                <Col span={4}>
                    <Statistic
                        title="Applicants"
                        value={numberOfApplicants || 0}
                        valueStyle={{ color: "#1890ff" }}
                    />
                </Col>
                <Col span={4}>
                    <Statistic
                        title="Shortlisted"
                        value={numberOfShortlisted || 0}
                        valueStyle={{ color: "#52c41a" }}
                    />
                </Col>
                <Col span={4}>
                    <Statistic
                        title="Interviews"
                        value={numberOfInterviewScheduled || 0}
                        valueStyle={{ color: "#faad14" }}
                    />
                </Col>
                <Col span={4}>
                    <Statistic
                        title="Rejected"
                        value={numberOfRejected || 0}
                        valueStyle={{ color: "#ff4d4f" }}
                    />
                </Col>
                <Col span={4}>
                    <Statistic
                        title="Hired"
                        value={numberOfHired || 0}
                        valueStyle={{ color: "#722ed1" }}
                    />
                </Col>
            </Row>
        </Card>
    );
};

export default JobPostingStats;