import React, { useState, useEffect, useRef } from "react";
import "./ApplicationItem.css";
import { useMutation } from "@apollo/client";
import { UPDATE_APPLICATION_STATUS } from "../../../utils/mutations.js"; // Import your GraphQL query
import { FAVOURITE_APPLICATION } from "../../../utils/mutations.js"; // Import your GraphQL query
import { DELETE_APPLICATION } from "../../../utils/mutations.js"; // Import your GraphQL query
import { Button, Dropdown, Menu, Typography, Checkbox, message, Popconfirm } from "antd";
import {
  SendOutlined,
  DownSquareOutlined,
  TeamOutlined,
  StarOutlined,
  StarFilled,
  PlusSquareFilled,
  MinusSquareFilled,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CheckOutlined
} from "@ant-design/icons";
import OptimizeResumeButton from "../../ApplicationButtons/OptimizeResume";
import MessageModal from "../../Modals/MessageModal"
import { Link } from 'react-router-dom';
import { useTour } from '../../../contexts/tourContext.js';
import NegotiationTips from "../../Modals/NegotiationTips";

const { Text } = Typography;


const ApplicationItem = ({ app, isFirst }) => {
  const [status, setStatus] = useState(app.status);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [updateApplication] = useMutation(
    UPDATE_APPLICATION_STATUS
  );
  const [MessageModalStatus, setMessageModalStatus] = useState(false);
  const [currentMessageType, setCurrentMessageType] = useState('');
  const [ifNetworked, setIfNetworked] = useState(app.dateNetworked);
  const [ifFollowedUp, setIfFollowedUp] = useState(app.dateFollowedUp);
  const [ifThankYouSent, setIfThankYouSent] = useState(app.dateThankYouSent);
  const [ifOfferAccepted, setIfOfferAccepted] = useState(app.dateAcceptedOffer);
  const [ifOfferDeclined, setIfOfferDeclined] = useState(app.dateDeclinedOffer);
  const [ifFeedbackSent, setIfFeedbackSent] = useState(app.dateRequestedFeedback);
  const [ifResumeOptimized, setIfResumeOptimized] = useState(app.resumeOptimized);
  const [ifCoverLetterOptimized, setIfCoverLetterOptimized] = useState(app.coverletterOptimized);
  const [ifInterviewPrepped, setIfInterviewPrepped] = useState(app.interviewPreped);
  const [ifNegotiationTipsReviewed, setIfNegotiationTipsReviewed] = useState(app.reviewedNegotiationTips);
  const [favouriteApplication] = useMutation(FAVOURITE_APPLICATION);
  const [isFavourite, setIsFavourite] = useState(app.favorite);
  const [showCheckList, setShowCheckList] = useState(isFirst);
  const [dateApplied, setDateApplied] = useState(app.dateApplied);
  const [deleteApplication] = useMutation(DELETE_APPLICATION);
  const [isFirstInstance, setIsFirstInstance] = useState(true);
  const [showNegotiationTips, setShowNegotiationTips] = useState(false);

  const { setTourSteps, startTour } = useTour();
  const ref = useRef(null);
  const hasAppliedRef = useRef(false);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  const today = new Date();

  useEffect(() => {
    setStatus(app.status);
  }, [app.status]);


  useEffect(() => {
    if (!hasAppliedRef.current && ref.current) {
      // Do something with the ref
      console.log(ref.current);
      // Mark that the ref has been applied
      hasAppliedRef.current = true;

      handleCheckList();
    }
  }, [ref.current]);

  //Not being used right now
  useEffect(() => {
    // Define the tour steps
    setTourSteps([
      {
        target: () => ref1.current,
        title: 'Change Application Status',
        description: 'Click the button or select from the dropdown to change the status of the application.',
      },
      // {
      //   target: () => ref2.current,
      //   title: 'Favorite Application',
      //   description: 'Click the star icon to favorite or unfavorite the application.'
      // },
      {
        target: () => ref3.current,
        title: 'Check List',
        description: 'Click the button to view the check list for the application.',
        nextButtonProps: { onClick: () => { if (!showCheckList) handleCheckList(); } }
      },
      {
        target: () => ref4.current,
        placement: 'top',
        title: 'Optimize',
        description: (
          <>
            Select a task to optimize your application. Completed tasks will show as highlighted.{' '}
            <strong>Let's start with enhancing your resume!</strong>
          </>
        ),
        mask: {
          color: 'rgba(0, 0, 0, 0.95)',
        },
      }
    ]);
    // startTour();
  }, [setTourSteps]);

  const openMessageModal = (type) => {
    setCurrentMessageType(type);
    setMessageModalStatus(true);
    console.log(MessageModalStatus);
  };

  const closeMessageModal = (NetworkDate, FollowUpDate) => {
    setMessageModalStatus(false);
  };

  const handleStatusClick = () => {
    // if (status === "Saved") {
    //   // Change status to "Applied" when clicked
    //   updateApplication({
    //     variables: {
    //       id: app._id,
    //       status: "Applied",
    //       dateApplied: today,
    //       recievedInterview: false,
    //       recievedOffer: false,
    //       rejected: false,
    //       dateArchived: null,

    //     },
    //   }).then(() => {
    //     setStatus("Applied");
    //   });
    // } else {
    // Toggle the dropdown when clicked on other statuses
    setDropdownOpen((prevOpen) => !prevOpen); // Toggle the dropdown visibility
    // }
  };

  const handleStatusChange = (newStatus) => {
    // Update application status and close the dropdown
    var updateVariables = {};
    if (newStatus == "Saved") {
      updateVariables = {
        dateApplied: null,
        recievedInterview: false,
        recievedOffer: false,
        rejected: false,
        dateArchived: null,
      }
    }
    else if (newStatus == "Applied") {
      updateVariables = {
        dateApplied: today,
        recievedInterview: false,
        recievedOffer: false,
        rejected: false,
        dateArchived: null,
      }
    }
    else if (newStatus == "Interviewing") {
      updateVariables = {
        recievedInterview: true,
        recievedOffer: false,
        rejected: false,
        dateArchived: null,
      }
    }
    else if (newStatus == "Offered") {
      updateVariables = {
        recievedOffer: true,
        rejected: false,
        dateArchived: null,
      }
    }
    else if (newStatus == "Archived") {
      updateVariables = {
        rejected: true,
        dateArchived: today,
      }
    }

    updateApplication({
      variables: {
        id: app._id,
        status: newStatus,
        ...updateVariables
      },
    }).then(() => {
      setStatus(newStatus); // Update the status in the component state
    });
    setDropdownOpen(false); // Close the dropdown
  };



  const menu = (
    <Menu>
      {["Saved", "Applied", "Interviewing", "Offered", "Archived"].map((option) => (
        <Menu.Item key={option} onClick={() => handleStatusChange(option)}>
          {option}
        </Menu.Item>
      ))}
    </Menu>
  );

  const calculateDaysPending = (dateApplied) => {
    const today = new Date(); // Current date
    const appDate = new Date(parseInt(dateApplied, 10)); // Convert 'dateApplied' to a Date object
    // Calculate the time difference in milliseconds
    const timeDifference = today - appDate;
    // Calculate the number of days by dividing the time difference by the number of milliseconds in a day (24 * 60 * 60 * 1000)
    const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
    return daysDifference;
  };

  const handleApplyClick = () => {
    // Open the application link in a new tab
    window.open(app.job.link, "_blank");
  };
  //Change to Applied
  const confirmApplied = () => {
    if (status === "Saved") {
      // Change status to "Applied" when clicked
      updateApplication({
        variables: {
          id: app._id,
          status: "Applied",
          dateApplied: today,
          recievedInterview: false,
          recievedOffer: false,
          rejected: false,
          dateArchived: null,

        },
      }).then(() => {
        setStatus("Applied");
      });
    }
    message.success('Application status changed to Applied');
  };
  const notChangeToApplied = () => {
    console.log('Clicked on No');
    message.error('Application status not changed');
  };


  const favApp = () => {
    favouriteApplication({
      variables: {
        id: app._id,
        favorite: !app.favorite,
      },
    });
    setIsFavourite(!isFavourite);
  }

  const handleCheckList = () => {
    setShowCheckList(!showCheckList);
    console.log(showCheckList);
  }

  const handleDelete = () => {
    deleteApplication({
      variables: {
        id: app._id,
      },
    });
    window.location.reload();
  }

  return (
    <div className="app-card">
      {/* <div onClick={handleDelete} className="close-button" style={{ fontSize: '11px', position: 'absolute', top: -7, right: -3, padding: '5px', cursor: 'pointer' }}>
        X
      </div> */}
      <div ref={hasAppliedRef ? ref2 : null} className="favorite-icon" onClick={favApp}>
        {isFavourite ? <StarFilled style={{ color: "#fcdc5c" }} /> : <StarOutlined style={{ color: "#5ce4f4" }} />}
      </div>
      <div ref={hasAppliedRef ? ref1 : null} className="status-section">
        <div className="status-buttons">
          <span
            className={`status-text ${status === "Saved" ? "saved" : ""
              }`}
          >
            {app.status} {/* Not using the Status State*/}
          </span>
          {/* {status === "Saved" ? ( */}
          {/* <Button className="apply-button" type="primary" onClick={handleStatusClick}>
              Apply
            </Button>
          ) : ( */}
          <Dropdown
            onClick={handleStatusClick}
            overlay={menu}
            trigger={["click"]}
            visible={isDropdownOpen}
          >
            <Button>
              Status <DownSquareOutlined />
            </Button>
          </Dropdown>
          {/* )} */}
        </div>
        <span className="post-date">
          {status === "Saved"
            ? `Post Date: ${app.job.formattedPostDate}`
            : app.dateApplied ? `Days Pending: ${calculateDaysPending(app.dateApplied)} days` : `Post Date: ${app.job.formattedPostDate}`}
        </span>
      </div>
      <div className="company-logo-section">
        <img
          // Prioritize the company logo, but fallback to the job posting logo if unavailable
          src={app.job.companyLogo}
          onError={(e) => {
            e.target.onerror = null; // Prevents infinite loop if default logo also fails
            e.target.src = app.job.logo;
          }}

          alt={app.job.companyTitle}
          className="company-logo"
        />
      </div>
      <div className="company-details-section">
        <p className="company-name">{app.job.companyTitle}</p>
        <a href={app.job.link} target="_blank" rel="noopener noreferrer">
          <p className="position">{app.job.position}</p>
        </a>
        <p className="location">{app.job.location}</p>
      </div>
      <div ref={isFirstInstance ? ref3 : null} className="actions-section">
        {!showCheckList ? (
          <Text onClick={() => handleCheckList()}>Check List <PlusSquareFilled style={{ color: '#2ca4cc' }} /></Text>
        ) : (
          <div className="actions-buttons">
            {status && (
              <>
                <div style={{ marginLeft: 'auto', marginRight: `auto` }}>
                  <Text style={{ fontSize: `18px`, textDecoration: 'underline' }}></Text>
                </div>
                {status === "Saved" && (
                  <>
                    <div ref={isFirstInstance ? ref4 : null} className="action-button-row">
                      <div className="action-row-number">
                        <Text>1</Text>
                      </div>
                      <OptimizeResumeButton jobID={app.job._id} appID={app._id} resumeOptimized={ifResumeOptimized} />
                    </div>
                    <div className="action-button-row">
                      <div className="action-row-number">
                        <Text>2</Text>
                      </div>
                      <Button
                        icon={<CheckCircleOutlined />}
                        style={{ background: ifCoverLetterOptimized ? "#fcdc5c" : "" }}
                      >
                        <Link to={`/application/coverletter/${app.job._id}/${app._id}`}>Optimize Cover Letter</Link>
                      </Button>
                    </div>
                    <div className="action-button-row">
                      <div className="action-row-number">
                        <Text>3</Text>
                      </div>
                      <Button
                        icon={<TeamOutlined />}
                        onClick={() => openMessageModal("Network")}
                        style={{ background: ifNetworked ? "#fcdc5c" : "" }}
                      >
                        Network
                      </Button>
                    </div>
                    <div className="action-button-row">
                      <div className="action-row-number">
                        <Text>4</Text>
                      </div>
                      <Popconfirm
                        title="Did you submit your application for this job?"
                        description="Update status to 'Applied'"
                        onConfirm={confirmApplied}
                        onCancel={notChangeToApplied}
                        okText="Applied"
                        cancelText="Cancel"
                        icon={<CheckOutlined style={{ color: 'green' }} />}
                      >
                        <Button icon={<SendOutlined />} onClick={handleApplyClick}>
                          Apply
                        </Button>
                      </Popconfirm>

                    </div>
                  </>
                )}
                {status === "Applied" && (
                  <>
                    <div className="action-button-row">
                      <div className="action-row-number">
                        <Text>1</Text>
                      </div>
                      <Button
                        icon={<TeamOutlined />}
                        onClick={() => openMessageModal("Network")}
                        style={{ background: ifNetworked ? "#fcdc5c" : "" }}
                      >
                        Network
                      </Button>
                    </div>
                    <div className="action-button-row">
                      <div className="action-row-number">
                        <Text>2</Text>
                      </div>
                      <Button
                        icon={<TeamOutlined />}
                        onClick={() => openMessageModal("Follow-Up")}
                        style={{ background: ifFollowedUp ? "#fcdc5c" : "" }}
                      >
                        Follow-Up</Button>
                    </div>
                  </>
                )}
                {status === "Interviewing" && (
                  <>
                    <div className="action-button-row">
                      <div className="action-row-number">
                        <Text>1</Text>
                      </div>
                      <Button
                        icon={<ExclamationCircleOutlined/>}
                        style={{ background: ifInterviewPrepped ? "#fcdc5c" : "" }}
                        >
                        <Link to={`/application/interview/${app.job._id}/${app._id}`}>Interview Prep</Link>
                      </Button>
                    </div>
                    <div className="action-button-row">
                      <div className="action-row-number">
                        <Text>2</Text>
                      </div>
                      <Button
                        icon={<TeamOutlined />}
                        onClick={() => openMessageModal("Thank-You")}
                        style={{ background: ifThankYouSent ? "#fcdc5c" : "" }}
                      >Thank You</Button>
                    </div>
                  </>
                )}
                {status === "Offered" && (
                  <>
                    <div className="action-button-row">
                      <div className="action-row-number">
                        <Text>1</Text>
                      </div>
                      <Button
                        icon={<ExclamationCircleOutlined />}
                        style={{ background: ifNegotiationTipsReviewed ? "#fcdc5c" : "" }}
                        onClick={() => setShowNegotiationTips(true)}
                      >
                        Negotiation Tips</Button>
                    </div>
                    <div className="action-button-row">
                      <div className="action-row-number">
                        <Text>2</Text>
                      </div>
                      <Button
                        icon={<TeamOutlined />}
                        onClick={() => openMessageModal("Acceptance")}
                        style={{ background: ifOfferAccepted ? "#fcdc5c" : "" }}
                      >Accept</Button>
                    </div>
                    <div className="action-button-row">
                      <div className="action-row-number">
                        <Text>3</Text>
                      </div>
                      <Button
                        icon={<TeamOutlined />}
                        onClick={() => openMessageModal("Rejection")}
                        style={{ background: ifOfferDeclined ? "#fcdc5c" : "" }}
                      >Decline</Button>
                    </div>
                  </>
                )}
                {status === "Archived" && (
                  <>
                    <Button
                      icon={<TeamOutlined />}
                      onClick={() => openMessageModal("Feedback")}
                      style={{ background: ifFeedbackSent ? "#fcdc5c" : "" }}
                    >Feedback</Button>
                  </>
                )}
              </>
            )}
            <div style={{ marginLeft: 'auto', marginRight: `auto` }}>
              <Text onClick={() => handleCheckList()}>Close List <MinusSquareFilled /></Text>
            </div>
          </div>
        )}

      </div>
      {MessageModalStatus && (
        <MessageModal onClose={closeMessageModal} app={app} messageType={currentMessageType} updateNetwork={setIfNetworked} updateFollowUp={setIfFollowedUp} updateThankYou={setIfThankYouSent} updateAcceptance={setIfOfferAccepted} updateDecline={setIfOfferDeclined} updateFeedback={setIfFeedbackSent} />
      )}

      {showNegotiationTips && (
        <NegotiationTips visible={showNegotiationTips} onClose={() => setShowNegotiationTips(false)} app={app} updateNegotiation={setIfNegotiationTipsReviewed} />
      )}

    </div>
  );
};

export default ApplicationItem;


