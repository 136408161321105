import React, { useState, useEffect } from "react";
import { Modal, Button, List, Checkbox, Divider } from "antd";
import { useMutation } from '@apollo/client';
import { ADD_APPLICATION } from '../../../utils/mutations.js'
import { useQuery } from '@apollo/client';
import { QUERY_MY_RESUMES_BY_USER_ID } from '../../../utils/queries';
import { Link } from 'react-router-dom';
import SignUpComponent from "../../SignUp";



const ApplyModal = ({ visible, onClose, job, setJustSaved, notLoggedIn }) => {

    const [addApplication, { error }] = useMutation(ADD_APPLICATION);
    const { data: dataResume, refetch } = useQuery(QUERY_MY_RESUMES_BY_USER_ID)
    const [resumes, setResumes] = useState([]);
    const [selectedResume, setSelectedResume] = useState(null);

    useEffect(() => {
        if (dataResume) {
            setResumes(dataResume.getAllResumeDataByUserID);
        }
    }, [dataResume])




    const handleApply = async (id) => {
        try {
            await addApplication({
                variables: { job: id, status: "Applied", resumeID: selectedResume, dateApplied: new Date() }
            });
            setJustSaved(true);
            onClose();
        } catch (err) {
            console.error(err);
        }
    }

    const handleSelectResume = (resumeId) => {
        setSelectedResume(resumeId);
    };


    return (
        <Modal
            title={<h2 style={{ fontSize: '20px' }}>Apply to {job.companyTitle} - {job.position}</h2>}
            centered
            visible={visible}
            onCancel={onClose}
            closable={false}
            footer={
                notLoggedIn ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            type="primary"
                            className="button-color"
                            onClick={(e) => {
                                e.stopPropagation();
                                onClose();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!selectedResume}
                            type="primary"
                            className="button-color-premium"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleApply(job._id);
                            }}
                        >
                            Apply
                        </Button>
                    </div>
                ) : null
            }
        >
            {notLoggedIn ? (
                <>
                    <p style={{ fontSize: '14px', marginBottom: '-5px' }}>Select a resume to apply with:</p>
                    <List
                        itemLayout="horizontal"
                        dataSource={resumes}
                        style={{ maxHeight: '300px', overflowY: 'auto' }} // Set max height and enable scrolling
                        renderItem={resume => (
                            <List.Item
                                actions={[
                                    <Button style={{ width: '100%', marginBottom: '2.5px' }} type="primary" className="button-color" size='small' onClick={(e) => e.stopPropagation()}>
                                        <Link to={`/edit-resume/${resume._id}`}>Edit</Link>
                                    </Button>,
                                    <Checkbox
                                        checked={selectedResume === resume._id}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={(e) => {
                                            handleSelectResume(resume._id);
                                        }}
                                    />
                                ]}
                            >
                                <List.Item.Meta
                                    title={resume.savedName}
                                />
                            </List.Item>
                        )}
                    />
                    <Divider />
                </>
            ) : (
                <>
                <SignUpComponent jobId={job._id} />
                </>
            )}

        </Modal>
    );

}


export default ApplyModal;

