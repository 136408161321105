import React, { useState } from 'react';
import { Modal, Input, Button } from 'antd';

const { TextArea } = Input;

const ApplicationNotesModal = ({ visible, onSave, onCancel, initialNotes }) => {
    const [notes, setNotes] = useState(initialNotes || '');

    const handleSave = () => {
        onSave(notes); // Pass the notes back to the parent component
    };

    return (
        <Modal
            title="Applicant Notes"
            visible={visible}
            onCancel={onCancel}
            footer={null} // Custom footer with Save and Cancel buttons
        >
            <TextArea
                rows={12}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Type your notes about the applicant here..."
            />
            <div style={{ marginTop: '16px', textAlign: 'right' }}>
                <Button onClick={onCancel} style={{ marginRight: '8px' }}>
                    Cancel
                </Button>
                <Button type="primary" className='button-color' onClick={handleSave}>
                    Save
                </Button>
            </div>
        </Modal>
    );
};

export default ApplicationNotesModal;