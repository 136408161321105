import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Divider, Card } from 'antd';
import './Home.css';
import ApplicationStatistic from '../components/ApplicationStatistic';
import RecommendToDos from '../components/Home/RecommendToDos';
import GoalChart from '../components/Home/GoalChart/index.js';
import MainStats from '../components/Home/MainStats/index.js';
import Feedback from '../components/Home/Feedback/index.js';
import { useTour } from '../contexts/tourContext.js';
import useIsMobile from '../contexts/useIsMobile';
import Target from '../components/Home/Target/index.js';


const Home = () => {
    const { setTourSteps, startTour } = useTour();
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const isMobile = useIsMobile();


    const statuses = ["Saved", "Applied", "Interviewing", "Offered", "Archived"]

    // useEffect(() => {
    //     // Define the tour steps
    //     setTourSteps([
    //         {
    //             title: 'Welcome to your Dashboard!',
    //             description: 'This is your personal dashboard. Here you can track your application progress and get recommendations to help you land your dream job.',
    //         },
    //         {
    //             target: () => ref1.current,
    //             placement: 'left',
    //             title: 'Your Stats',
    //             description: 'Track the progress of your job search.',
    //         },
    //         {
    //             target: () => ref2.current,
    //             placement: 'left',
    //             title: 'Recommended To-Dos',
    //             description:
    //                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    //                     <img src='/screen/todo.png' alt='ToDo' style={{ width: '80%', height: '80%' }} />
    //                     <p style={{
    //                         marginTop: '10px',
    //                         textAlign: 'center',
    //                     }}>As you progress through your job search, we will provide you with recommendations to help you land your dream job.</p>
    //                 </div>,
    //         },
    //     ]);
    //     // Start the tour
    //     //startTour();

    //     var isFirstTimeHome = localStorage.getItem('isFirstTimeHome');
    //     var firstVist = localStorage.getItem('firstVisit');

    //     if (firstVist) {
    //         if (!isFirstTimeHome) {
    //             startTour();
    //             localStorage.setItem('isFirstTimeHome', 'false');
    //         }
    //     }
    // }, [setTourSteps]);


    return (
        <div className='homepage'>

            <div style={{ width: '80%', margin: '0 auto' }}>

                {/* Row 1 */}
                {/* Application Stages */}
                {!isMobile ?
                    <div>
                        {/* className="dashboard-section application-stages" */}
                        {/* <Target /> */}
                        {/* <Row justify="space-evenly">
                        {statuses.map((status) => (<ApplicationStatistic key={status.status} status={status} />))}
                    </Row> */}
                    </div>
                    : null}


                {/* Row 2 */}
                <div className="flex-container" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', alignItems: 'flex-start' }}>
                    {/* Col 1 - Main Stats */}
                    <div ref={ref1} className='mainStats'>
                        <MainStats />
                    </div>
                    {/* Col 2 - toDos */}
                    <div className='toDos'>
                        <div style={{ marginBottom: '10px' }}>
                            <Target />
                        </div>
                        <div ref={ref2} style={{ flex: 1 }}>
                            <RecommendToDos />
                        </div>
                        {/* <Divider /> */}
                        {/* <div style={{ flex: 1 }}>
                            <Feedback />
                        </div> */}
                    </div>
                </div>

                {!isMobile ?
                    <div className="">
                        <Card style={{marginTop: '10px'}}>
                            <Row justify="space-evenly">
                                {statuses.map((status) => (<ApplicationStatistic key={status.status} status={status} />))}
                            </Row>
                        </Card>
                    </div>
                    : null}

                {isMobile ?
                    <div className="dashboard-section application-stages">
                        <Row justify="space-evenly">
                            <p>Application Stages</p>
                            {statuses.map((status) => (<ApplicationStatistic key={status.status} status={status} />))}
                        </Row>
                    </div>
                    : null}


            </div>


            {/* <div style={{ flex: 1, margin: '10px' }}>
                    <GoalChart />
                </div> */}



            {/* Section 2: Recommended Jobs to Apply */}
            {/* <div className="recommended-jobs-section"> */}
            {/* <h2>Recommended Jobs</h2> */}
            {/* <Row gutter={16}> */}
            {/* Render 4 recommended job cards here */}
            {/* {jobPostings.slice(0, 4).map((job, index) => (
                        <Col span={6} key={index}>
                            <JobPostingItem job={job} />
                        </Col>
                    ))} */}
            {/* </Row> */}
            {/* </div> */}

            {/* Section 3: Recommended Follow-up Jobs */}
            {/* <div className="follow-up-jobs-section"> */}
            {/* <h2>Recommended Follow Ups</h2> */}
            {/* <Row gutter={16}> */}
            {/* Render 8 follow-up job cards here */}
            {/* {applications.slice(0, 8).map((app, index) => ( */}
            {/* <Col span={6} key={index}> */}
            {/* <JobPostingItem job={app.job} /> */}
            {/* </Col> */}
            {/* ))} */}
            {/* </Row> */}
            {/* </div> */}
        </div>
    );
};

export default Home;


