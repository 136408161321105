import React, { useEffect, useState } from 'react';
import ApplicationItem from '../../components/Cards/ApplicationItem/index.js';
import { useQuery } from '@apollo/client'; // Import the appropriate query hook
import { QUERY_MY_APPLICATIONS } from '../../utils/queries.js'
import { List, Button, Empty, Spin, Space, Divider, Drawer, Card, Statistic } from 'antd'; // Import Ant Design components
import './ApplicationsList.css';
import AddApplicationModal from '../../components/Modals/AddApplicationModal';
import { FilterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { useLocation } from 'react-router-dom';


//<Link to="/applications?filter1=value1&filter2=value2">Applications</Link>

const ApplicationsList = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { data, loading, refetch } = useQuery(QUERY_MY_APPLICATIONS);
  const [isLoading, setIsLoading] = useState(true);
  const [applications, setApplications] = useState([]);
  const [filter, setFilter] = useState(queryParams.get('filter') || 'Saved');
  const [sortOrder, setSortOrder] = useState('asc');
  const [fav, setFav] = useState(queryParams.get('fav') || false);
  const [resumeOptimized, setResumeOptimized] = useState(queryParams.get('resumeoptimized') || 'All');
  const [coverletterOptimized, setCoverletterOptimized] = useState(queryParams.get('cloptimized') || 'All');
  const [networked, setNetworked] = useState(queryParams.get('networked') || 'All');
  const [followUp, setFollowUp] = useState(queryParams.get('followedup') || 'All');
  const [isAddAppOpen, setIsAddAppOpen] = useState(false);
  const statuses = ["Saved", "Applied", "Interviewing", "Offered", "Archived"]
  const [openFilters_Sort, setOpenFilters_Sort] = useState(false);

  useEffect(() => {
    if (data) {
      setApplications(data.myApplications);
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (location.pathname === '/applications' || !isAddAppOpen) {
      refetch();
    }
  }, [location.pathname, isAddAppOpen, refetch]);

  useEffect(() => {
    if (data) {
      refetch();
    }
  }, [data, refetch]);

  //Count of applications based on status
  // useEffect(() => {
  //   if (data) {
  //     const applicationsByStatus = data.myApplications.reduce((acc, app) => {
  //       // Increase the count for the app's status
  //       acc[app.status] = (acc[app.status] || 0) + 1;
  //       return acc;
  //     }, {});

  //     // Get the total count of all applications
  //     const totalCount = data.myApplications.length;

  //     console.log(applicationsByStatus); // { Saved: 5, Applied: 3, ... }
  //     console.log(totalCount); // 8
  //   }
  // }, [data]);

  const showDrawer = () => {
    setOpenFilters_Sort(true);
  };

  const onClose = () => {
    setOpenFilters_Sort(false);
  };

  const openAddApp = () => {
    setIsAddAppOpen(true);
  };

  const closeAddApp = () => {
    setIsAddAppOpen(false);
  };

  const handleFilterClick = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  const handleSortClick = (order) => {
    // Set the sortOrder state to 'asc' or 'desc' based on the order parameter
    setSortOrder(order);
  };

  const sortApplications = (apps, order) => {
    return apps.slice().sort((a, b) => {
      if (filter === 'All' || filter === 'Saved') {
        const dateA = new Date(a.job.formattedPostDate);
        const dateB = new Date(b.job.formattedPostDate);

        if (order === 'asc') {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      } else {
        const dateA = a.dateApplied;
        const dateB = b.dateApplied;

        if (order === 'asc') {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      }
    });
  };

  // Filter applications based on the selected filter
  const filteredApplications = applications.filter((app) => {
    if (filter === 'All') {
      return true; // Show all applications when 'All' is selected
    }
    // Filter based on the selected status (you can adjust this condition)
    return app.status === filter;
  });

  const applyFilters = (apps) => {
    const filterFunctions = [];

    if (fav) {
      filterFunctions.push((app) => app.favorite === true);
    }

    if (resumeOptimized === 'yes') {
      filterFunctions.push((app) => app.resumeOptimized == true);
    } else if (resumeOptimized === 'no') {
      filterFunctions.push((app) => app.resumeOptimized == false);
    }

    if (coverletterOptimized === 'yes') {
      filterFunctions.push((app) => app.coverletterOptimized == true);
    } else if (coverletterOptimized === 'no') {
      filterFunctions.push((app) => app.coverletterOptimized == false);
    }

    if (networked === 'yes') {
      filterFunctions.push((app) => app.dateNetworked !== null);
    } else if (networked === 'no') {
      filterFunctions.push((app) => app.dateNetworked === null);
    }

    if (followUp === 'yes') {
      filterFunctions.push((app) => app.dateFollowedUp !== null);
    } else if (followUp === 'no') {
      filterFunctions.push((app) => app.dateFollowedUp === null);
    }

    return apps.filter((app) => filterFunctions.every((filterFn) => filterFn(app)));
  };

  const filteredAndSortedApplications = sortApplications(
    applyFilters(filteredApplications),
    sortOrder
  );

  const handleFavClick = () => {
    setFav(!fav);
  };

  const handleResumeOptimizedClick = (value) => {
    if (value === resumeOptimized) {
      setResumeOptimized('All');
      return;
    }
    setResumeOptimized(value);
  };

  const handleCoverletterOptimizedClick = (value) => {
    if (value === coverletterOptimized) {
      setCoverletterOptimized('All');
      return;
    }
    setCoverletterOptimized(value);
  };

  const handleNetworkedClick = (value) => {
    if (value === networked) {
      setNetworked('All');
      return;
    }
    setNetworked(value);
  };

  const handleFollowUpClick = (value) => {
    if (value === followUp) {
      setFollowUp('All');
      return;
    }
    setFollowUp(value);
  }

  return (
    <div>
      {/* Title Section */}
      <div className="applist-title-section" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2 className="title" style={{ fontFamily: "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'", textAlign: 'center', fontSize: '34px', fontWeight: 'bold' }}>My Applications</h2>
        <div className="button-container">
          <Button className="button-color" type="primary" onClick={showDrawer}>
            <FilterOutlined />
          </Button>
          <Button className="button-color add-application-button" type="primary" onClick={openAddApp}>
            Add New Job Posting
          </Button>
        </div>
      </div>
      {/* Filter and Sort Buttons Section */}
      <div className="filter-sort-section">
        {/* <span>Filter:</span> */}
        <Button
          type="default"
          block={true}
          onClick={() => handleFilterClick('All')}
          // Add className "active" to the button based on the selected filter
          className={filter === 'All' ? 'active' : ''}
        >
          {`All (${applications.length})`}
        </Button>
        <Button
          type="default"
          block={true}
          onClick={() => handleFilterClick('Saved')}
          className={filter === 'Saved' ? 'active' : ''}
        >
          {`Saved (${applications.filter(app => app.status === 'Saved').length})`}
        </Button>
        <Button
          type="default"
          block={true}
          onClick={() => handleFilterClick('Applied')}
          className={filter === 'Applied' ? 'active' : ''}
        >
          {`Applied (${applications.filter(app => app.status === 'Applied').length})`}
        </Button>
        <Button
          type="default"
          block={true}
          onClick={() => handleFilterClick('Interviewing')}
          className={filter === 'Interviewing' ? 'active' : ''}
        >
          {`Interviewing (${applications.filter(app => app.status === 'Interviewing').length})`}
        </Button>
        <Button
          type="default"
          block={true}
          onClick={() => handleFilterClick('Offered')}
          className={filter === 'Offered' ? 'active' : ''}
        >
          {`Offered (${applications.filter(app => app.status === 'Offered').length})`}
        </Button>
        <Button
          type="default"
          block={true}
          onClick={() => handleFilterClick('Archived')}
          className={filter === 'Archived' ? 'active' : ''}
        >
          {`Archived (${applications.filter(app => app.status === 'Archived').length})`}
        </Button>
      </div>


      <Drawer
        title="Filters & Sort"
        placement={"right"}
        onClose={onClose}
        open={openFilters_Sort}
      // key={placement}
      >
        <div className="filter-sort-drawer-section" style={{ display: 'flex', flexDirection: 'column' }}>
          <Space direction="vertical" size="middle">
            <Space>
              <span>Favorites Only:</span>
              <Button
                type="default"
                size="small"
                onClick={() => handleFavClick()}
                className={fav === true ? 'active' : ''}
              >
                Favorites
              </Button>
            </Space>
            <Divider type="vertical" />
            <Space>
              <span>Sort:</span>
              <Button
                type="default"
                size="small"
                onClick={() => handleSortClick('asc')}
              >
                Ascending
              </Button>
              <Button
                type="default"
                size="small"
                onClick={() => handleSortClick('desc')}
              >
                Descending
              </Button>
            </Space>
            <Divider type="vertical" />
            <Space>
              <span>Resume Optimized:</span>
              <Button
                type="default"
                size="small"
                onClick={() => handleResumeOptimizedClick("yes")}
                className={resumeOptimized === 'yes' ? 'active' : ''}
              >
                Yes
              </Button>
              <Button
                type="default"
                size="small"
                onClick={() => handleResumeOptimizedClick("no")}
                className={resumeOptimized === 'no' ? 'active' : ''}
              >
                No
              </Button>
            </Space>
            <Divider type="vertical" />
            <Space>
              <span>Cover Letter Optimized:</span>
              <Button
                type="default"
                size="small"
                onClick={() => handleCoverletterOptimizedClick("yes")}
                className={coverletterOptimized === 'yes' ? 'active' : ''}
              >
                Yes
              </Button>
              <Button
                type="default"
                size="small"
                onClick={() => handleCoverletterOptimizedClick("no")}
                className={coverletterOptimized === 'no' ? 'active' : ''}
              >
                No
              </Button>
            </Space>
            <Divider type="vertical" />
            <Space>
              <span>Networked:</span>
              <Button
                type="default"
                size="small"
                onClick={() => handleNetworkedClick("yes")}
                className={networked === 'yes' ? 'active' : ''}
              >
                Yes
              </Button>
              <Button
                type="default"
                size="small"
                onClick={() => handleNetworkedClick("no")}
                className={networked === 'no' ? 'active' : ''}
              >
                No
              </Button>
            </Space>
            <Divider type="vertical" />
            <Space>
              <span>Followed-Up:</span>
              <Button
                type="default"
                size="small"
                onClick={() => handleFollowUpClick("yes")}
                className={followUp === 'yes' ? 'active' : ''}
              >
                Yes
              </Button>
              <Button
                type="default"
                size="small"
                onClick={() => handleFollowUpClick("no")}
                className={followUp === 'no' ? 'active' : ''}
              >
                No
              </Button>
            </Space>
          </Space>
        </div>
      </Drawer>

      {isLoading ? (
        <Spin size="large" />
      ) : null}

      {/* List of Applications */}
      {applications.length > 0 && !isLoading ? (
        <List
          dataSource={filteredAndSortedApplications}
          loading={loading}
          renderItem={(app, index) => (
            <List.Item key={app._id}>
              <ApplicationItem app={app} isFirst={index === 0} />
            </List.Item>
          )}
        />
      ) : (
        <Empty
          description={
            <div style={{ fontSize: '24px', color: '#888', textAlign: 'center' }}>
              <div style={{ fontSize: '26px', color: '#333' }}>
                Check out <Link to="/matcher"><strong>Opportunities</strong></Link> to discover and save job postings.
              </div>
              <div style={{ fontSize: '20px', color: '#333' }}>Or</div>
              <div style={{ fontSize: '26px', color: '#333' }}>
                Click "<strong>Add New Job Posting</strong>" to manually add your first job application!
              </div>
            </div>
          }
        />
      )}
      {isAddAppOpen && (
        <AddApplicationModal onClose={closeAddApp} />
      )}
    </div>

  );
};

export default ApplicationsList;