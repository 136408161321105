import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Typography, Table, Button, Checkbox, Dropdown, Menu, Space } from 'antd';
import { DownOutlined, StarFilled, StarOutlined, CheckCircleFilled } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import JobPostingItem from '../components/Cards/JobPostingItem';
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { QUERY_JOB_POSTING_BY_COMPANY_ID, QUERY_APPLICATIONS_BY_JOB_ID } from '../utils/queries';
import { SHORTLIST_APPLICATION, UPDATE_COMPANY_STATUS_APPLICATION, UPDATE_COMPANY_NOTES_APPLICATION } from '../utils/mutations';
import { UserContext } from '../contexts/userContext';
import ResumePreviewer from '../components/Previewers/ResumePreviewer';
import './CompanyHome.css'; // Import the CSS file
import ContactMessageModal from '../components/Modals/ContactMessageModal';
import { getEvaluateTaskResult, getEvaluateTaskStatus, startEvaluateTask } from '../utils/EvaluateAPIs/evaluate';
import { backgroundJobCheck } from '../utils/helpers/background';
import { handleDownload } from '../utils/StoredResumeAPIs';
import JobPostingStats from '../components/Cards/JobPostingsStats'
import ApplicationNotesModal from '../components/Modals/ApplicationNotesModal';
import BroadCastMessageModal from '../components/Modals/BroadCastMessageModal';

const { Title } = Typography;

const CompanyHome = () => {
    const { user, allMyEmails } = useContext(UserContext);

    // State to track selected job posting
    const [selectedJob, setSelectedJob] = useState(null);
    const [jobPostings, setJobPostings] = useState([]);
    const [selectedResume, setSelectedResume] = useState(null);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [currentContact, setCurrentContact] = useState(null);
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [modalMessageType, setModalMessageType] = useState("");
    const [currenApplicantion, setCurrentApplication] = useState(null);
    const [evaluationLoading, setEvaluationLoading] = useState(false);
    const [notesModalVisible, setNotesModalVisible] = useState(false);
    const [intialNotes, setInitialNotes] = useState(null);
    const [viewBroadcastMessageModal, setViewBroadCastMessageModal] = useState(false);

    const [numberOfApplicants, setNumberOfApplicants] = useState(0)
    const [numberOfShortlisted, setNumberOfShortlisted] = useState(0)
    const [receivedInterview, setReceivedInterview] = useState(0)
    const [rejected, setRejected] = useState(0)
    const [hired, setHired] = useState(0)

    const [revealBulkActions, setRevealBulkActions] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [bulkStatus, setBulkStatus] = useState(null);
    const [bulkEmailData, setBulkEmailData] = useState(null);

    // Fetch job postings for the company
    const { loading: loadingJobPostings, data: dataJobPostings } = useQuery(QUERY_JOB_POSTING_BY_COMPANY_ID, {
        variables: { company: user.associatedCompany._id },
    });

    // Fetch applications for the selected job posting
    const { loading: loadingApplications, data: dataApplications } = useQuery(QUERY_APPLICATIONS_BY_JOB_ID, {
        variables: { job: selectedJob?._id },
        skip: !selectedJob, // Skip query if no job is selected
    });

    const [shortlistApplication] = useMutation(SHORTLIST_APPLICATION);
    const [updateCompanyStatusApplication] = useMutation(UPDATE_COMPANY_STATUS_APPLICATION);
    const [updateCompanyNotesApplication] = useMutation(UPDATE_COMPANY_NOTES_APPLICATION);

    useEffect(() => {
        if (dataJobPostings) {
            setJobPostings(dataJobPostings.findJobPostingsByCompany);
        }
    }, [dataJobPostings]);

    useEffect(() => {
        if (dataApplications) {
            setNumberOfApplicants(dataApplications?.getApplicationsForJobPosting.length || 0)// Safely handle undefined or null

            // Count number of shortlisted applications
            const shortlistedCount = dataApplications?.getApplicationsForJobPosting.filter(
                (application) => application.shortlist === true
            ).length || 0;
            setNumberOfShortlisted(shortlistedCount);

            // Counting interview scheduled
            const interviewCount = dataApplications?.getApplicationsForJobPosting.filter(
                (application) => application.dateInterviewScheduled != null
            ).length || 0;
            setReceivedInterview(interviewCount)

            // Counting rejected applications
            const rejectedCount = dataApplications?.getApplicationsForJobPosting.filter(
                (application) => application.companyStatus === "Rejected"
            ).length || 0;
            setRejected(rejectedCount)
            // Counting hired applications
            const hiredCount = dataApplications?.getApplicationsForJobPosting.filter(
                (application) => application.companyStatus === "Hired"
            ).length || 0;
            setHired(hiredCount)
        }
    }, [dataApplications])

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys) => {
            setSelectedRowKeys(selectedKeys); // Update selected rows

            // Map selected keys to their corresponding row data
            const selectedData = dataApplications?.getApplicationsForJobPosting.filter((application) =>
                selectedKeys.includes(application._id)
            ).map((application) => ({
                firstName: application.user?.firstName || '',
                lastName: application.user?.lastName || '',
                email: application.user?.customUserEmail || '',
                userID: application.user?._id || '',
            }));

            setBulkEmailData(selectedData); // Update bulk email data with selected rows
        },
    };

    const handleBulkAction = () => {
        // Perform bulk action on selected rows
        console.log('Selected rows:', selectedRowKeys);
        // Add your bulk action logic here
    };

    //Establish Options: Reviewing, Reviewed, Interview Requested, Interview Scheduled, Interview Completed, Offer Made, Offer Accepted, Offer Declined, Rejected
    const statusOptions = ["Reviewing", "Reviewed", "Interview Requested", "Interview Scheduled", "Interview Completed", "Offer Made", "Offer Accepted", "Offer Declined", "Rejected"];

    //Handle Status Change
    const handleStatusChange = async (applicationID, status) => {
        try {
            await updateCompanyStatusApplication({
                variables: { id: applicationID, companyStatus: status },
            });
        } catch (err) {
            console.error(err);
        }
    };

    //Handle Notes Save
    const handleNotesSave = async (applicationID, notes) => {
        try {
            await updateCompanyNotesApplication({
                variables: { id: applicationID, companyNotes: notes },
            });
            setNotesModalVisible(false); // Close the modal after saving
        } catch (err) {
            console.error(err);
        }
    };

    const handleJobClick = (job, event) => {
        event.stopPropagation();
        event.preventDefault();
        setSelectedJob(job);
    };

    const handleBackClick = () => {
        setSelectedJob(null);
    };

    const handleViewClick = (resumeID) => {
        setSelectedResume(resumeID);
    };

    const handleCloseViewClick = () => {
        setSelectedResume(null);
    };

    const formatDate = (timestamp) => {
        const date = new Date(parseInt(timestamp, 10));
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    const openMessageModal = () => {
        setIsMessageModalOpen(true);
    };

    const closeMessageModal = () => {
        setIsMessageModalOpen(false);
    };

    const handleShortlist = async (applicationID, shortlist) => {
        try {
            await shortlistApplication({
                variables: { id: applicationID, shortlist: shortlist },
            });
        } catch (err) {
            console.error(err);
        }
    };

    //Evaluate Candidacy Function
    const evaluateCandidacy = async (resumeID, applicationID) => {

        setEvaluationLoading(true);

        try {
            let taskResponse = await startEvaluateTask(user._id, resumeID, selectedJob.jobFields[0]._id, selectedJob.experienceLevel, applicationID, selectedJob._id);
            let taskId = taskResponse.data.taskID;

            let evaluationResultsPromise = backgroundJobCheck(getEvaluateTaskStatus, getEvaluateTaskResult, taskId);

            // Create a timeout promise that resolves after 2 seconds
            const timeoutPromise = new Promise(resolve => setTimeout(resolve, 2000));

            var result = await Promise.all([evaluationResultsPromise, timeoutPromise]);

            //refetch();

            return result;
        } catch (error) {
            console.error("Error during evaluation:", error);
            // Handle the error appropriately, e.g., show a notification to the user
        } finally {
            setEvaluationLoading(false);

            //     if (result[0].result == "No Resume Data Found") {
            //         openNotificationWithIcon('error', 'No Resume Data Found', 'Please upload your resume to evaluate your candidacy.');
            //     }

            //     if (result[0].result == "Error") {
            //         openNotificationWithIcon('error', 'Evaluation Failed', 'An error occurred while evaluating your candidacy. Please try again later. If the problem persists, please contact support.');
            //     }
        }
    }

    if (loadingJobPostings) {
        return <div>Loading...</div>;
    }

    const columns = [
        {
            title: "Shortlist",
            dataIndex: 'shortlist',
            key: 'shortlist',
            render: (shortlist, record) => (
                <span
                    onClick={() => handleShortlist(record._id, !shortlist)} // Toggle shortlist status
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        fontSize: '18px',
                    }}
                >
                    {shortlist ? <StarFilled style={{ color: '#fadb14' }} /> : <StarOutlined />}
                </span>
            ),
            width: '2%'
        },
        {
            title: 'Date Applied',
            dataIndex: 'dateApplied',
            key: 'dateApplied',
            render: (dateApplied) => formatDate(dateApplied),
        },
        {
            title: 'Name',
            key: 'fullName',
            render: (text, record) => `${record.user?.firstName || ''} ${record.user?.lastName || ''}`,
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
            render: (text, record) => (
                <>
                    <span>{record.applicantScore ? record.applicantScore + "%" : ''}</span>
                    {!record.applicantScore &&
                        <Button onClick={() => evaluateCandidacy(record.resumeID._id, record._id)} loading={evaluationLoading}>Evaluate</Button>
                    }
                </>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'companyStatus',
            key: 'companyStatus',
            render: (text, record) => {
                const menu = (
                    <Menu
                        onClick={(e) => handleStatusChange(record._id, e.key)}
                        selectedKeys={[record.companyStatus]}
                    >
                        {statusOptions.map((status) => (
                            <Menu.Item key={status}>
                                {status}
                            </Menu.Item>
                        ))}
                    </Menu>
                );

                return (
                    <Dropdown overlay={menu} trigger={['click']}>
                        <Space>
                            {record.companyStatus || 'Reviewing'}
                            <DownOutlined />
                        </Space>
                    </Dropdown>
                );
            },
        },
        {
            title: 'Resume',
            key: 'resume',
            render: (record) => (
                <>
                    <Button onClick={() => handleViewClick(record.resumeID?._id)}>View</Button>
                    <Button
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownload(record.user?._id)}
                        style={{ marginLeft: '8px' }}
                    />
                </>
            ),
        },
        {
            title: 'Contact',
            dataIndex: ['user'],
            key: 'user',
            render: (text, record) => (
                <div> {/* Center-align the content */}
                    <Button
                        className="button-color"
                        size="small"
                        onClick={() => {
                            setSelectedEmails(allMyEmails.filter(email => email.userContactId && email.userContactId._id === record.user._id));
                            openMessageModal();
                            setCurrentContact(record.user);
                            setCurrentApplication(record._id);
                            setModalMessageType("network");
                        }}
                    >
                        Contact
                    </Button>
                    {record.applicantLastContacted && (
                        <div style={{ marginTop: '5px', fontSize: '12px', color: '#888' }}>
                            {formatDate(record.applicantLastContacted)}
                        </div>
                    )}
                </div>
            )

        },
        {
            title: 'Notes',
            dataIndex: 'companyNotes',
            key: 'companyNotes',
            render: (text, record) => (
                <Button
                    onClick={() => {
                        setCurrentApplication(record._id);
                        setInitialNotes(record.companyNotes);
                        setNotesModalVisible(true);
                    }}
                >
                    Notes
                </Button>
            ),
        },

    ];

    return (
        <div style={{ padding: '20px' }}>
            {!selectedJob ? (
                <>
                    {/* Select Posting */}
                    <Title level={2}>Select Your Job Posting</Title>
                    <Row gutter={[16, 16]}>
                        {jobPostings?.map((job) => (
                            <Col key={job._id} xs={24} sm={12} md={8}>
                                <div onClick={(event) => handleJobClick(job, event)}>
                                    <JobPostingItem job={job} forMatcher={true} noClick={true} />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </>
            ) : (
                <>
                    {/* View Applications */}
                    <Button onClick={handleBackClick} style={{ marginBottom: '20px' }}>
                        Back to Job Postings
                    </Button>
                    <div style={{ marginBottom: '20px' }}>
                        <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
                            <Col xs={24} sm={12}>
                                <JobPostingItem job={selectedJob} forMatcher={true} />
                            </Col>
                            <Col xs={24} sm={12}>
                                <JobPostingStats job={selectedJob}
                                    numberOfApplicants={numberOfApplicants}
                                    numberOfShortlisted={numberOfShortlisted}
                                    numberOfInterviewScheduled={receivedInterview}
                                    numberOfRejected={rejected}
                                    numberOfHired={hired}
                                />
                            </Col>
                        </Row>
                    </div>
                    <Title level={4}>Applications for {selectedJob.position}</Title>
                    {loadingApplications ? (
                        <div>Loading applications...</div>
                    ) : (
                        <>
                            <Button
                                type="primary"
                                onClick={() => setRevealBulkActions(!revealBulkActions)} // Toggle bulk actions visibility
                                // disabled={selectedRowKeys.length === 0} // Disable button if no rows are selected
                                style={{ marginBottom: '10px' }}
                            >
                                Bulk Actions
                            </Button>

                            {/* Bulk Actions */}
                            {revealBulkActions && (
                                <>
                                    <div style={{ marginBottom: '10px' }}>

                                        <Space.Compact
                                            style={{ marginBottom: '10px', alignItems: 'center' }}
                                        >
                                            <span style={{ marginRight: '8px', fontWeight: 'bold' }}>Update Status:</span>
                                            <Dropdown
                                                overlay={
                                                    <Menu
                                                        onClick={(e) => setBulkStatus(e.key)} // Set the selected status for bulk action
                                                        selectedKeys={[bulkStatus]} // Highlight the currently selected status
                                                    >
                                                        {statusOptions.map((status) => (
                                                            <Menu.Item key={status}>
                                                                {status}
                                                            </Menu.Item>
                                                        ))}
                                                    </Menu>
                                                }
                                                trigger={['click']}
                                            >
                                                <Button>
                                                    {bulkStatus || 'Select Status'} <DownOutlined />
                                                </Button>
                                            </Dropdown>
                                            <Button
                                                className="button-color"
                                                style={{
                                                    width: '1px',
                                                    margin: '0',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                                type="primary"
                                                onClick={handleBulkAction}
                                            >
                                                <CheckCircleFilled />
                                            </Button>
                                        </Space.Compact>
                                    </div>
                                    <div style={{ marginBottom: '10px' }}>
                                        <Button
                                            className="button-color"
                                            onClick={() => setViewBroadCastMessageModal(true)}
                                        >
                                            Send Broadcast Message
                                        </Button>
                                    </div>
                                </>
                            )}

                            <Row gutter={[16, 16]} className="full-height-row">
                                <Col span={selectedResume ? 12 : 24} className="full-height-col">
                                    <Table
                                        rowSelection={rowSelection} // Enable row selection
                                        dataSource={dataApplications?.getApplicationsForJobPosting}
                                        columns={columns}
                                        rowKey="_id"
                                        pagination={{ pageSize: 20 }} // Optional: Adjust pagination for better usability
                                        scroll={{ x: '100%' }} // Optional: Enable horizontal scrolling if needed
                                    />
                                </Col>
                                <Col span={12} className="full-height-col">
                                    {selectedResume && (
                                        <>
                                            <Button onClick={handleCloseViewClick} style={{ marginBottom: '10px' }}>
                                                Close Preview
                                            </Button>
                                            <ResumePreviewer ResumeID={selectedResume} />
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            )}
            {isMessageModalOpen && (
                <ContactMessageModal onClose={closeMessageModal} record={currentContact} contact={"applicant"} messageType={modalMessageType} myEmails={selectedEmails} applicationID={currenApplicantion} />
            )}
            {notesModalVisible && (
                <ApplicationNotesModal
                    visible={notesModalVisible}
                    onSave={(notes) => handleNotesSave(currenApplicantion, notes)}
                    onCancel={() => setNotesModalVisible(false)}
                    initialNotes={intialNotes}
                />
            )}
            {viewBroadcastMessageModal && (
                <BroadCastMessageModal
                    selectedIds={selectedRowKeys}
                    selectContactEmailData={bulkEmailData}
                    onClose={() => setViewBroadCastMessageModal(false)}
                />
            )}
        </div>
    );

};

export default CompanyHome;