import React, { useEffect, useState } from "react";
import '../Card.css'
import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { ADD_APPLICATION } from '../../../utils/mutations.js'
import { QUERY_JOBS_SAVED } from '../../../utils/queries.js'
import { Card, Button } from 'antd'
import MessageModal from "../../Modals/MessageModal"
import { Link } from 'react-router-dom';
import ApplyModal from "../../Modals/ApplyModal"

import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom


const JobPostingItem = ({ job, forMatcher, noClick }) => {
  const location = useLocation(); // Get the current route location

  const [addApplication, { error }] = useMutation(ADD_APPLICATION);
  const { loading, savedError, data, refetch } = useQuery(QUERY_JOBS_SAVED, { variables: { id: job._id } })
  const [isSaved, setIsSaved] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const [appStatus, setAppStatus] = useState("");

  const [MessageModalStatus, setMessageModalStatus] = useState(false);
  const [currentMessageType, setCurrentMessageType] = useState('');
  const [applyModalVisible, setApplyModalVisible] = useState(false);

  const openMessageModal = (type) => {
    setCurrentMessageType(type);
    setMessageModalStatus(true);
    console.log(MessageModalStatus);
  };

  const closeMessageModal = () => {
    setMessageModalStatus(false);
  };

  useEffect(() => {
    if (location.pathname === '/') {
      refetch(); // Refetch the data when the route is "/applications"
      console.log("refetching");
    }
  }, [location.pathname, refetch]);


  useEffect(() => {
    if (data) {
      setIsSaved(data.getApplicationByJobID.success);
      setAppStatus(data.getApplicationByJobID.status);
      if (data.getApplicationByJobID.status === "Applied") {
        setIsApplied(true);
      }
    }
  }, [data])

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const handleSave = async (id) => {
    try {
      await addApplication({
        variables: { job: id, status: "Saved" }
      });
      setIsSaved(true);
      setAppStatus("Saved");
    } catch (error) {
      // Handle any errors that occur during the mutation.
      console.error("Error adding application:", error);
    }
  }

  const actionButton =
    !isSaved ? (
      <>
        <Button className="button-color" onClick={() => handleSave(job._id)} type="primary">
          Save
        </Button>
        {job.internal && (
          <Button
            type="primary"
            className="button-color-premium"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              setApplyModalVisible(!applyModalVisible);
            }}
          >
            Auto Apply
          </Button>)}
      </>
    )
      : isApplied ? (
        <div>
          <Button onClick={() => openMessageModal("Follow-Up")} type="default">Follow Up</Button>
        </div>
      )
        : (
          <span>Status: {appStatus}</span>
        );




  const content = (
    <>
      <Card className="posting-card">
        <div className="item-label">Job Posting</div>
        <div className="logo-container">
          <img
            // Prioritize the company logo, but fallback to the job posting logo if unavailable
            src={job.companyLogo}
            onError={(e) => {
              console.error(job.companyLogo);
              e.target.onerror = null; // Prevents infinite loop if default logo also fails
              e.target.src = job.logo;
            }}

            alt={`${job.companyTitle} Logo`}
            className="logo-image"
            loading="lazy"
          />
        </div>
        <div className="text-container">
          <span className="company-name">{job.companyTitle}</span>
          <h4>
            <a
              href={`${job.link}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()} // Stop event propagation
            >
              {job.position}
            </a>
          </h4>
          <div className="location">
            <span className="icon flaticon-map-locator"></span>
            {job.location}
          </div>
        </div>
        {!forMatcher &&
          <>
            <hr className="divider" /> {/* Add a horizontal line */}
            <div className="action-container">{actionButton}</div>
          </>
        }
        {/* <MessageModal
              visible={MessageModalStatus}
              onClose={closeMessageModal}
              messageType={currentMessageType}
              app={app}
            /> */}
      </Card>
      {/* Apply Modal */}
      {applyModalVisible && (
        <ApplyModal
          visible={applyModalVisible}
          onClose={() => setApplyModalVisible(false)}
          job={job}
          setJustSaved={setIsSaved}
        />
      )}
    </>
  );

  return noClick ? (
    <div className="job-posting-link">{content}</div>
  ) : (
    <Link to={`/job/${job._id}`} className="job-posting-link">{content}</Link>
  );
};

export default JobPostingItem;
