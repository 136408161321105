import React, { useState, useEffect, useContext } from 'react';
import { QuestionCircleOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { FloatButton } from 'antd';
import WelcomeModal from '../../Modals/WelcomeModal';
import GuideModal from '../../Modals/GuideModal';
import { useTour } from '../../../contexts/tourContext';
import NewHelpModal from '../../Modals/NewHelpModal';
import { UserContext } from '../../../contexts/userContext';
import useIsMobile from '../../../contexts/useIsMobile';

const HelpButton = () => {
    const [visible, setVisible] = useState(false);
    const [openWelcome, setOpenWelcome] = useState(false);
    const [openGuide, setOpenGuide] = useState(false);
    const { startTour } = useTour();
    const { user, refetchUser } = useContext(UserContext);
    const isMobile = useIsMobile();
    const [countryCodeEmpty, setCountryCodeEmpty] = useState(false);
    const [signUpJob, setSignUpJob] = useState(null);

    useEffect(() => {
        // const firstVisit = localStorage.getItem('firstVisit');
        // if (!firstVisit) {
        //     setOpenWelcome(true);
        //     localStorage.setItem('firstVisit', 'true');
        // }

        //Trigger welcome modal if user has not set target job
        if (user && !user.targetJob) {
            if (!user.myResumeData) {
                setSignUpJob(user.signUpWithJob || null);
            }
            setOpenWelcome(true);
        }

        //Trigger welcome modal if user has not set target location country code
        if (user && !user.targetLocation.countryCode) {
            setOpenWelcome(true);
            setCountryCodeEmpty(true);
        } else {
            setCountryCodeEmpty(false);
        }

    }, [user]);

    useEffect(() => {
        if (openWelcome) {
            document.body.classList.add('no-scroll-background-main');
        } else {
            document.body.classList.remove('no-scroll-background-main');
        }

        // Cleanup on component unmount
        return () => {
            document.body.classList.remove('no-scroll-background-main');
        };
    }, [openWelcome]);

    onclose = () => {
        setOpenWelcome(false);
        setVisible(false);
    }



    return (
        <>
            {!isMobile || countryCodeEmpty ? (
                <FloatButton
                    icon={<QuestionCircleOutlined style={{ color: 'white', fontSize: '20px' }} />}
                    shape="square"
                    className='float-button-color'
                    description="Help"
                    style={{
                        right: 45,
                        display: user?.accountType === "company" ? "none" : "block",
                    }}
                    onClick={() => {
                        //startTour();
                        setVisible(true);
                        //setOpenGuide(true);
                    }}
                />
            ) : null}
            {openWelcome && <WelcomeModal visible onClose={onclose} signUpJob={signUpJob} />}
            {visible && <NewHelpModal visible onClose={onclose} />}
            {/* {openGuide && <GuideModal visible onClose={() => setOpenGuide(false)} />} */}
        </>
    );
}

export default HelpButton;