import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Button, Typography, Card, Space, Divider, Collapse, Alert, Input } from 'antd';
import { SearchOutlined, FileTextOutlined, TeamOutlined, BulbOutlined } from '@ant-design/icons';
import EditResumeModal from './EditResumeModal';
import { UserContext } from '../../../../../contexts/userContext';
import { useMutation } from '@apollo/client';
import { ADD_APPLICATION } from '../../../../../utils/mutations';

const { Title, Text } = Typography
const { TextArea } = Input;


const JobApplicationReview = ({ setStep, disableNext, signUpJob }) => {

    const { user } = useContext(UserContext);
    const [addApplication, { error }] = useMutation(ADD_APPLICATION);
    const [viewEditResume, setViewEditResume] = useState(false);
    const [appliedSuccess, setAppliedSuccess] = useState(false);
    const [selectedResume, setSelectedResume] = useState(null);
    const [message, setMessage] = useState("")

    useEffect(() => {
        if (user && user.resume) {
            setSelectedResume(user.myResumeData._id);
        }
    }, [user]);

    useEffect(() => {
        if (!appliedSuccess) {
            disableNext(true);
        } else {
            disableNext(false);
        }
    }, []);


    const handleApply = async () => {
        try {
            await addApplication({
                variables: { job: signUpJob, status: "Applied", resumeID: selectedResume, dateApplied: new Date(), message_or_cl: message }
            });
            setAppliedSuccess(true);
            disableNext(false)
        } catch (err) {
            console.error(err);
        }
    }

    return (
        !appliedSuccess ? (
            <>
                <Card bordered={false} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <Space direction="vertical" size="middle" style={{ textAlign: 'center' }} >
                        <Title level={4}>Application Details</Title>
                        <Text><strong>First Name:</strong> {user.firstName}</Text>
                        <Text><strong>Last Name:</strong> {user.lastName}</Text>
                        <Text><strong>Email:</strong> {user.email}</Text>
                    </Space>
                </Card>

                <Space direction="horizontal" size="middle" style={{ marginTop: '5%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: '20px' }}>{user.firstName} {user.lastName}'s Resume</Text>
                    <Button type="primary" className='button-color' onClick={() => setViewEditResume(true)}>View</Button>
                </Space>

                <TextArea
                    rows={4}
                    placeholder="Message/Cover Letter (Optional)"
                    style={{ marginTop: '5%' }}
                    value={message} // Bind the state to the TextArea
                    onChange={(e) => setMessage(e.target.value)} // Update the state on change
                />

                <div style={{ marginTop: '5%' }}>
                    <Button type="primary" className='button-color-premium' onClick={handleApply}>Apply</Button>
                </div>

                <EditResumeModal visible={viewEditResume} onClose={() => setViewEditResume(false)} />
            </>
        ) : (
            <>
                <Alert
                    message={<strong>Application Submitted!</strong>}
                    description={
                        <>
                            <p style={{ fontSize: '20px' }}>Thank you for applying! You will be notified if the employer is interested in your application.</p>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                                <FileTextOutlined style={{ fontSize: '36px', color: '#52c41a', marginRight: '10px' }} />
                                <Text style={{ width: '50%' }}>
                                    Complete your sign-up to track your application status and receive real-time updates
                                </Text>
                            </div>
                        </>
                    }
                    type="success"
                    showIcon
                />
                {/* Main Title */}
                <Title level={3} style={{ marginTop: '2.5%', textAlign: 'center' }}>
                    Take Control of Your Job Search with Hiremize!
                </Title>

                {/* Features Section */}
                <Row gutter={[16, 16]} style={{ marginTop: '2%' }}>
                    <Col xs={24} sm={8} md={8} lg={8} style={{ textAlign: 'center' }}>
                        <SearchOutlined style={{ fontSize: '56px', color: '#2ca4cc' }} />
                        <Text style={{ fontSize: '16px', display: 'block', marginTop: '10px' }}>
                            Get matched with thousands of opportunities tailored just for you!
                        </Text>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8} style={{ textAlign: 'center' }}>
                        <TeamOutlined style={{ fontSize: '56px', color: '#2ca4cc' }} />
                        <Text style={{ fontSize: '16px', display: 'block', marginTop: '10px' }}>
                            Connect with insiders at your target companies and fast-track your path to getting hired!
                        </Text>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8} style={{ textAlign: 'center' }}>
                        <BulbOutlined style={{ fontSize: '56px', color: '#2ca4cc' }} />
                        <Text style={{ fontSize: '16px', display: 'block', marginTop: '10px' }}>
                            Assess your candidacy and receive expert job-hunting guidance with our AI-powered tools!
                        </Text>
                    </Col>
                </Row>


                {/* Additional Text */}
                <Text style={{ fontSize: '14px', marginTop: '5%', display: 'block', textAlign: 'center' }}>
                    <strong>Estimated Time to Complete Sign-Up: 1-2 Minutes</strong>
                </Text>
            </>
        )
    )

}


export default JobApplicationReview;