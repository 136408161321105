import React, { useEffect, useState } from "react";
import { Card, Button } from 'antd'
import './OpportunityCollectionCard.css';


const OpportunityCollectionCard = ({ opportunity, changeCategory }) => {

  return (
    <Card onClick={() => changeCategory(opportunity.categoryName)} className="opportunity-card">
            <p className="opportunity-title">{opportunity.title}</p>
      <div className="image-container">
        <img src={opportunity.image} alt="opportunity" className="opportunity-image" />
      </div>
      <div className="page-fold"></div>
    </Card>
  );
}

export default OpportunityCollectionCard;