import UploadResume from './StepComponents/UploadResume';
import JobDetails from './StepComponents/JobDetails';
import TargetJob from './StepComponents/Target-Job';
import TargetCompanies from './StepComponents/TargetCompanies';
import Review from './StepComponents/Review';
import WelcomeStep from './StepComponents/WelcomeStep';
import JobApplicationReview from './StepComponents/JobApplicationReview';





export const steps = (jobData, welcomeWizardData, setWelcomeWizardData, setStepRelative, close, disableNext, loadingMatches, signUpJob) => [
    {
        title: 'Welcome!',
        content:
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <WelcomeStep disableNext={disableNext} />
            </div>
    },
    {
        title: 'Get Started!',
        content:
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <UploadResume setStep={setStepRelative} disableNext={disableNext} signUpJob={signUpJob}/>
            </div>
    },
    {
        title: 'Job Application Review',
        content:
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <JobApplicationReview signUpJob={signUpJob} setStep={setStepRelative} disableNext={disableNext} />
            </div>
    },
    {
        title: 'Target Job',
        content:
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <TargetJob jobData={jobData} userData={welcomeWizardData} setUserData={setWelcomeWizardData} setStep={setStepRelative} disableNext={disableNext} />
            </div>
    },
    // {
    //     title: 'Target Companies',
    //     content:
    //         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
    //             <TargetCompanies jobData={jobData} userData={welcomeWizardData} setUserData={setWelcomeWizardData} setStep={setStepRelative} disableNext={disableNext} />
    //         </div>

    // },
    {
        title: 'Job Details',
        content:
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <JobDetails jobData={jobData} userData={welcomeWizardData} setUserData={setWelcomeWizardData} setStep={setStepRelative} disableNext={disableNext} />
            </div>
    },
    {
        title: 'Review',
        content:
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Review userData={welcomeWizardData} close={close} setStep={setStepRelative} loadingMatches={loadingMatches} />
            </div>
    },
];