import axios from 'axios';


export const uploadResume = async (userId, file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await axios.post(`/users/${userId}/resume`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;
    } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
    }
}

export const handleDownload = async (userId) => {
    try {

        // Send a GET request to the server to fetch the file
        const response = await axios.get(`/users/${userId}/resume`, {
            responseType: 'blob'
        });

        // Create a URL for the file blob
        const url = URL.createObjectURL(response.data);

        // Open the file in a new window
        window.open(url);
    } catch (error) {
        // Log any errors that occur
        console.error(error);
    }
};

