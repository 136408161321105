import OpportunityImage from '../../../assets/OpportunityCollections/Opportunity.png'; // Correct import syntax
import BusInternsImage from '../../../assets/OpportunityCollections/BusInterns.png'; // Correct import syntax
import TechInternsImage from '../../../assets/OpportunityCollections/TechInterns.png'; // Correct import syntax
import AIImage from '../../../assets/OpportunityCollections/AI.png'; // Correct import syntax
import BigBankImage from '../../../assets/OpportunityCollections/BigBanks.png'; // Correct import syntax
import BigFourImage from '../../../assets/OpportunityCollections/BigFour.png'; // Correct import syntax
import BigTechImage from '../../../assets/OpportunityCollections/BigTech.png'; // Correct import syntax
import EntryAnalystImage from '../../../assets/OpportunityCollections/EntryAnalyst.png'; // Correct import syntax
import EntryConsultingImage from '../../../assets/OpportunityCollections/Consulting.png'; // Correct import syntax
import JuniorSoftwareDeveloperImage from '../../../assets/OpportunityCollections/SoftwareDev.png'; // Correct import syntax
import InvestBankingImage from '../../../assets/OpportunityCollections/InvestmentBanking.png'; // Correct import syntax
// import RemoteImage from '../../../assets/OpportunityCollections/Remote.png'; // Correct import syntax
import NewGradImage from '../../../assets/OpportunityCollections/NewGrad.png'; // Correct import syntax
import DataAndAnalyticsImage from '../../../assets/OpportunityCollections/DataAnalytics.png'; // Correct import syntax



const Collections = {
    TopBusinessInternships: {
        image: BusInternsImage,
        title: "Top Business Internships",
        categoryName: "Top Student Business Internships",
    },
    TopAIInternships: {
        image: AIImage,
        title: "AI Centric Jobs",
        categoryName: "AI Centric Jobs",
    },
    JobsAtBigBanks: {
        image: BigBankImage,
        title: "Jobs at Big Banks",
        categoryName: "Jobs at Big Banks",
    },
    TopTechInternships: {
        image: TechInternsImage,
        title: "Top Student Tech Internships",
        categoryName: "Top Student Tech Internships",
    },
    JobsAtBigFour: {
        image: BigFourImage,
        title: "Jobs at Big Four",
        categoryName: "Jobs at Big Four",
    },
    JobsInBigTech: {
        image: BigTechImage,
        title: "Jobs in Big Tech",
        categoryName: "Jobs in Big Tech",
    },
    EntryConsultingJobs: {
        image: EntryConsultingImage,
        title: "Entry Level Consulting Roles",
        categoryName: "Entry Level Consulting Roles",
    },
    TopEntryAnalystJobs: {
        image: EntryAnalystImage,
        title: "Top Entry Analyst Roles",
        categoryName: "Top Entry Analyst Roles",
    },
    JuniorSoftwareDeveloperJobs: {
        image: JuniorSoftwareDeveloperImage,
        title: "Junior Software Developer Roles",
        categoryName: "Junior Software Developer Roles",
    },
    InvestBankingRelatedJobs: {
        image: InvestBankingImage,
        title: "Investment Banking Related Roles",
        categoryName: "Investment Banking Related Roles",
    },
    // TopRemoteJobs: {
    //     image: OpportunityImage,
    //     title: "Top Remote Jobs",
    //     categoryName: "Top Remote Jobs",
    // },
    NewGradRoles: {
        image: NewGradImage,
        title: "New Grad Roles",
        categoryName: "New Grad Roles",
    },
    DataAndAnalyticsRoles: {
        image: DataAndAnalyticsImage,
        title: "Data and Analytics Roles",
        categoryName: "Data and Analytics Roles",
    },
};


export default Collections;