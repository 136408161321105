import { gql } from '@apollo/client';


//----------------------------------------------------------User Related Mutations----------------------------------------------------------//
export const ADD_USER = gql`
mutation Mutation($firstName: String!, $lastName: String!, $email: String!, $password: String!, $signUpWithJob: String) {
  addUser(firstName: $firstName, lastName: $lastName, email: $email, password: $password, signUpWithJob: $signUpWithJob) {
    token
    user {
      _id
    }
  }
}
`;

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        _id
      }
    }
  }
`;

export const UPDATE_GMAIL = gql`
mutation Mutation($email: String!, $accessToken: String!, $refreshToken: String!) {
  updateGmail(email: $email, accessToken: $accessToken, refreshToken: $refreshToken) {
    _id
  }
}
`;

export const ADD_MY_RESUME_DATA = gql`
mutation Mutation($myResumeData: ID!) {
  addmyResumeData(myResumeData: $myResumeData) {
    _id
  }
}
`;

export const UPDATE_MY_GOALS = gql`
mutation Mutation($applications: Int, $networking: Int) {
  updateMyGoals(applications: $applications, networking: $networking) {
    _id
  }
}
`;

export const UPDATE_MY_SAVED_RESUMES = gql`
mutation Mutation($resumeData: ID!, $slot: Int, $resumeName: String) {
  updateSavedResume(resumeData: $resumeData, slot: $slot, resumeName: $resumeName) {
    _id
  }
}
`;

export const UPDATE_MY_SAVED_COVERLETTER = gql`
mutation Mutation($coverLetterData: ID!) {
  updateSavedCoverLetterData(coverLetterData: $coverLetterData) {
    _id
  }
}
`;

export const SET_WELCOME_WIZARD = gql`
mutation Mutation($targetJob: ID!, $targetJobLevel: String!, $targetLocation: targetLocationInput, $targetCompanies: [ID], $yearsExperience: Int) {
  setWelcomeWizard(targetJob: $targetJob, targetJobLevel: $targetJobLevel, targetLocation: $targetLocation, targetCompanies: $targetCompanies, yearsExperience: $yearsExperience) {
    _id
  }
}
`

export const ADD_KEYWORDS_TO_USER = gql`
mutation Mutation($keywords: [String]) {
  addUserKeywords(keywords: $keywords) {
    keywords
  }
}
`;

export const REMOVE_KEYWORDS_FROM_USER = gql`
mutation Mutation($keywords: [String]) {
  removeUserKeywords(keywords: $keywords) {
    _id
  }
}
`;

export const ADD_MATCHER_EMAIL_TO_USER = gql`
mutation Mutation($matcherEmails: matcherEmailsInput) {
  addMatcherEmails(matcherEmails: $matcherEmails) {
    _id
  }
}
`;

export const UPDATE_MATCHER_EMAIL = gql`
mutation Mutation($userId: ID!, $emailId: ID!, $input: matcherEmailsInput) {
  updateMatcherEmail(userId: $userId, emailId: $emailId, input: $input) {
    _id
  }
}
`;

export const ADD_MATCH_LIKED = gql`
mutation Mutation {
  addMatchLiked {
    _id
  }
}
`;

export const EDIT_MATCHER_SIGNATURE = gql`
mutation Mutation($id: ID!, $input: MatcherSignatureInput) {
  editMatcherSignature(_id: $id, input: $input) {
    _id
  }
}
`;

export const SEND_PASSWORD_RESET_EMAIL = gql`
mutation SendPasswordResetEmail($email: String!) {
  sendPasswordResetEmail(email: $email) {
    _id
  }
}
`

export const RESET_PASSWORD = gql`
mutation resetPassword($token: String!, $password: String!) {
  resetPassword(token: $token, password: $password)
}
`


//----------------------------------------------------------Resume Data Related Mutations----------------------------------------------------------//
export const ADD_RESUME_DATA = gql`
mutation Mutation($mainData: CoreResumeDataInput, $dataType: String, $saved: Boolean, $theme: String, $savedName: String) {
  addResumeData(mainData: $mainData, dataType: $dataType, saved: $saved, theme: $theme, savedName: $savedName) {
    _id
  }
}
`;

export const UPDATE_RESUME_DATA = gql`
mutation Mutation($id: ID!, $mainData: CoreResumeDataInput, $saved: Boolean, $savedName: String) {
  editResumeData(_id: $id, mainData: $mainData, saved: $saved, savedName: $savedName) {
    _id
  }
}
`;

//----------------------------------------------------------Cover Letter Data Related Mutations----------------------------------------------------------//
export const ADD_CL_DATA = gql`
mutation Mutation($userData: userDataInput, $currentDate: String, $jobData: jobDataInput, $coverLetterParagraphs: [String]) {
  addCoverLetterData(userData: $userData, currentDate: $currentDate, jobData: $jobData, coverLetterParagraphs: $coverLetterParagraphs) {
    _id
  }
}
`;

export const UPDATE_CL_DATA = gql`
mutation EditCoverLetterData($id: ID!, $userData: userDataInput, $currentDate: String, $jobData: jobDataInput, $coverLetterParagraphs: [String]) {
  editCoverLetterData(_id: $id, userData: $userData, currentDate: $currentDate, jobData: $jobData, coverLetterParagraphs: $coverLetterParagraphs) {
    _id
  }
}
`;

//----------------------------------------------------------Job Posting Related Mutations----------------------------------------------------------//
export const ADD_JOB_POSTING = gql`
mutation Mutation($remote: Boolean, $city: String, $provinceOrState: String, $company: String!, $position: String!, $link: String!, $status: String!, $postDate: String) {
  addJobPosting(remote: $remote, city: $city, provinceOrState: $provinceOrState, company: $company, position: $position, link: $link, status: $status, postDate: $postDate) {
    _id
  }
}
`;
export const UPDATE_PARSE_JOB_DESCRIPTION = gql`
mutation Mutation($id: ID!, $parsedDescription: String) {
  editJobPosting(_id: $id, parsedDescription: $parsedDescription) {
    _id
  }
}
`;

export const UPDATE_JOB_DESCRIPTION = gql`
mutation EditJobPosting($id: ID!, $description: String) {
  editJobPosting(_id: $id, description: $description) {
    _id
  }
}
`;

export const NOT_INTERESTED_JOB_POSTING = gql`
mutation Mutation($id: ID!, $notInterestedTo: ID!) {
  notInterestedJobPosting(_id: $id, notInterestedTo: $notInterestedTo) {
    _id
  }
}
`;
//----------------------------------------------------------Application Related Mutations----------------------------------------------------------//
export const ADD_APPLICATION = gql`
mutation Mutation($job: ID!, $status: String, $resumeID: ID, $dateApplied: String, $message_or_cl: String) {
  addApplication(job: $job, status: $status, resumeID: $resumeID, dateApplied: $dateApplied, message_or_cl: $message_or_cl) {
    _id
  }
}
`;

export const UPDATE_APPLICATION_STATUS = gql`
mutation Mutation($id: ID!, $status: String, $dateApplied: String, $recievedInterview: Boolean, $recievedOffer: Boolean, $rejected: Boolean, $dateArchived: String) {
  updateApplication(_id: $id, status: $status, dateApplied: $dateApplied, recievedInterview: $recievedInterview, recievedOffer: $recievedOffer, rejected: $rejected, dateArchived: $dateArchived) {
    _id
    status
    dateApplied
    dateArchived
  }
}
`;

export const FAVOURITE_APPLICATION = gql`
mutation Mutation($id: ID!, $favorite: Boolean) {
  updateApplication(_id: $id, favorite: $favorite) {
    _id
    favorite
  }
}
`;

export const UPDATE_APPLICATION_FOLLOWUP_OR_NETWORK = gql`
mutation Mutation($id: ID!, $dateFollowedUp: String, $dateNetworked: String, $dateThankYouSent: String, $dateAcceptedOffer: String, $dateDeclinedOffer: String, $dateRequestedFeedback: String) {
  updateApplication(_id: $id, dateFollowedUp: $dateFollowedUp, dateNetworked: $dateNetworked, dateThankYouSent: $dateThankYouSent, dateAcceptedOffer: $dateAcceptedOffer, dateDeclinedOffer: $dateDeclinedOffer, dateRequestedFeedback: $dateRequestedFeedback) {
    _id
  }
}
`;

export const DELETE_APPLICATION = gql`
mutation Mutation($id: ID!) {
  deleteApplication(_id: $id) {
    _id
  }
}
`;

export const UPDATE_APPLICATION_RESUMEID = gql`
mutation Mutation($id: ID!, $resumeOptimized: Boolean, $resumeId: ID) {
  updateApplication(_id: $id, resumeOptimized: $resumeOptimized, resumeID: $resumeId) {
    _id
  }
}
`;

export const UPDATE_APPLICATION_COVERLETTERID = gql`
mutation Mutation($id: ID!, $coverletterOptimized: Boolean, $coverletterId: ID) {
  updateApplication(_id: $id, coverletterOptimized: $coverletterOptimized, coverletterID: $coverletterId) {
    _id
  }
}
`;

export const UPDATE_INTERVIEW_OR_NEGOTIATION_PREP = gql`
mutation Mutation($id: ID!, $interviewPreped: Boolean, $reviewedNegotiationTips: Boolean) {
  updateApplication(_id: $id, interviewPreped: $interviewPreped, reviewedNegotiationTips: $reviewedNegotiationTips) {
    _id
  }
}
`;

export const UPDATE_APPLICANT_LAST_CONTACTED = gql`
mutation Mutation($id: ID!, $applicantLastContacted: String) {
  companyApplicationUpdates(_id: $id, applicantLastContacted: $applicantLastContacted) {
    _id
    applicantLastContacted
  }
}
`;

export const SHORTLIST_APPLICATION = gql`
mutation Mutation($id: ID!, $shortlist: Boolean) {
  companyApplicationUpdates(_id: $id, shortlist: $shortlist) {
    _id
    shortlist
  }
}
`;

export const UPDATE_COMPANY_STATUS_APPLICATION = gql`
mutation Mutation($id: ID!, $companyStatus: String) {
  companyApplicationUpdates(_id: $id, companyStatus: $companyStatus) {
    _id
    companyStatus
  }
}
`;

export const UPDATE_COMPANY_NOTES_APPLICATION = gql`
mutation Mutation($id: ID!, $companyNotes: String) {
  companyApplicationUpdates(_id: $id, companyNotes: $companyNotes) {
    _id
    companyNotes
  }
}
`;



//----------------------------------------------------------Contact Related Mutations----------------------------------------------------------//
export const ADD_CONTACT = gql`
mutation AddContact($firstName: String!, $lastName: String, $company: CompanyInput, $jobTitle: String, $linkedIn: String, $email: String, $phone: String, $postingStatus: String) {
  addContact(firstName: $firstName, lastName: $lastName, company: $company, jobTitle: $jobTitle, linkedIn: $linkedIn, email: $email, phone: $phone, postingStatus: $postingStatus) {
    _id
  }
}
`;

export const EDIT_CONTACT = gql`
mutation Mutation($id: ID!, $firstName: String, $lastName: String, $company: CompanyInput, $jobTitle: String, $linkedIn: String, $email: String, $phone: String) {
  editContact(_id: $id, firstName: $firstName, lastName: $lastName, company: $company, jobTitle: $jobTitle, linkedIn: $linkedIn, email: $email, phone: $phone) {
    _id
  }
}
`;

export const EDIT_CONTACT_REPLIED = gql`
mutation Mutation($id: ID!, $replied: Boolean, $repliedDate: String, $repliedMessage: String) {
  editUserContact(_id: $id, replied: $replied, repliedDate: $repliedDate, repliedMessage: $repliedMessage) {
    _id
    replied
    repliedDate
    repliedMessage
  }
}
`;

export const EDIT_CONTACT_RELATION = gql`
mutation Mutation($id: ID!, $relationship: String) {
  editUserContact(_id: $id, relationship: $relationship) {
    _id
    relationship
  }
}
`;

export const ADD_JOB_POSTING_W_DATE_TO_CONTACT = gql`
mutation Mutation($id: ID!, $relatedJobPosting: ID, $lastContacted: String, $lastContactMessage: String) {
  editUserContact(_id: $id, relatedJobPosting: $relatedJobPosting, lastContacted: $lastContacted, lastContactMessage: $lastContactMessage) {
    _id
  }
}
`;

export const UPDATE_LAST_FOLLOW_UP = gql`
mutation Mutation($id: ID!, $lastfollowUp: String, $lastfollowUpMethod: String, $lastfollowUpMessage: String) {
  editUserContact(_id: $id, lastfollowUp: $lastfollowUp, lastfollowUpMethod: $lastfollowUpMethod, lastfollowUpMessage: $lastfollowUpMessage) {
    _id
  }
}
`;

export const FAVOURITE_CONTACT = gql`
mutation Mutation($id: ID!, $favourite: Boolean) {
  editUserContact(_id: $id, favourite: $favourite) {
    _id
    favourite
  }
}
`;

export const UPDATE_MEETING_DATE = gql`
mutation Mutation($id: ID!, $meetingDate: String, $meetingStart: String, $meetingEnd: String) {
  editUserContact(_id: $id, meetingDate: $meetingDate, meetingStart: $meetingStart, meetingEnd: $meetingEnd) {
    meeting {
      date
      start
      end
    }
  }
}
`;

export const SAVE_CONTACT = gql`
mutation Mutation($contact: ID!, $relationship: String) {
  saveContact(contact: $contact, relationship: $relationship ) {
    _id
    contactDetails {
      _id
    }
  }
}
`;
//----------------------------------------------------------Company Related Mutations----------------------------------------------------------//
export const ADD_COMPANY = gql`
mutation AddCompany($companyName: String!, $companyWebsite: String, $companyLinkedIn: String, $companyEmail: String, $companyPhone: String, $companyAddress: String, $companyCity: String, $companyStateOrProvince: String, $industry: String, $size: String, $yearFounded: String, $dateAdded: String, $postingStatus: String) {
  addCompany(companyName: $companyName, companyWebsite: $companyWebsite, companyLinkedIn: $companyLinkedIn, companyEmail: $companyEmail, companyPhone: $companyPhone, companyAddress: $companyAddress, companyCity: $companyCity, companyStateOrProvince: $companyStateOrProvince, industry: $industry, size: $size, yearFounded: $yearFounded, dateAdded: $dateAdded, postingStatus: $postingStatus) {
    _id
  }
}
`;

export const EDIT_COMPANY = gql`
mutation EditCompany($id: ID!, $companyName: String, $companyWebsite: String, $companyLinkedIn: String, $companyEmail: String, $companyPhone: String, $companyAddress: String, $companyCity: String, $companyStateOrProvince: String, $industry: String, $size: String, $yearFounded: String, $notes: String, $dateAdded: String) {
  editCompany(_id: $id, companyName: $companyName, companyWebsite: $companyWebsite, companyLinkedIn: $companyLinkedIn, companyEmail: $companyEmail, companyPhone: $companyPhone, companyAddress: $companyAddress, companyCity: $companyCity, companyStateOrProvince: $companyStateOrProvince, industry: $industry, size: $size, yearFounded: $yearFounded, notes: $notes, dateAdded: $dateAdded) {
    _id
  }
}
`;

export const UPDATE_LAST_CONTACTED_COMPANY = gql`
mutation EditUserCompany($id: ID!, $lastContacted: String, $lastContactMethod: String, $lastContactMessage: String) {
  editUserCompany(_id: $id, lastContacted: $lastContacted, lastContactMethod: $lastContactMethod, lastContactMessage: $lastContactMessage) {
    _id
  }
}
`;

export const UPDATE_LAST_FOLLOW_UP_COMPANY = gql`
mutation EditUserCompany($id: ID!, $lastfollowUp: String, $lastfollowUpMethod: String, $lastfollowUpMessage: String) {
  editUserCompany(_id: $id, lastfollowUp: $lastfollowUp, lastfollowUpMethod: $lastfollowUpMethod, lastfollowUpMessage: $lastfollowUpMessage) {
    _id
  }
}
`;

export const UPDATE_COMPANY_REPLIED = gql`
mutation EditUserCompany($id: ID!, $replied: Boolean, $repliedDate: String, $repliedMessage: String) {
  editUserCompany(_id: $id, replied: $replied, repliedDate: $repliedDate, repliedMessage: $repliedMessage) {
    _id
  }
}
`;


export const FAVOURITE_COMPANY = gql`
mutation EditUserCompany($id: ID!, $favourite: Boolean) {
  editUserCompany(_id: $id, favourite: $favourite) {
    _id
    favourite
  }
}
`;

export const SAVE_COMPANY = gql`
mutation Mutation($company: ID!) {
  saveCompany(company: $company) {
    _id
    companyDetails {
      _id
    }
  }
}
`;

export const NOT_INTERESTED_COMPANY = gql`
mutation Mutation($id: ID!, $notInterestedTo: ID!) {
  notInterestedCompany(_id: $id, notInterestedTo: $notInterestedTo) {
    _id
  }
}
`;

export const LINK_CONTACT_TO_COMPANY = gql`
mutation EditUserCompany($id: ID!, $linkedIndvContact: ID) {
  editUserCompany(_id: $id, linkedIndvContact: $linkedIndvContact) {
    _id
  }
}
`;


//----------------------------------------------------------Email Related Mutations----------------------------------------------------------//
export const MARK_EMAIL_READ = gql`
mutation Mutation($id: ID!, $read: Boolean) {
  markEmailAsRead(_id: $id, read: $read) {
    _id
  }
}
`;

//----------------------------------------------------------Job Related Mutations----------------------------------------------------------//

export const ADD_JOB = gql`
mutation AddJob($title: String!, $jobFields: ID!, $custom: Boolean) {
  addJob(title: $title, jobFields: $jobFields, custom: $custom) {
    _id
    title
    jobFields {
        Keywords
      }
  }
}
`;