import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { LOGIN } from '../utils/mutations';
import Auth from '../utils/auth';
import { Button, Checkbox, Form, Input, Layout, notification } from 'antd';
import ReactPlayer from 'react-player'
import logo from '../assets/logo.png';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import Signup from './Signup';
import './Login.css';
import useIsMobile from '../contexts/useIsMobile';

const { Sider, Content } = Layout;

function Login(props) {
  const [form] = Form.useForm();
  const [login] = useMutation(LOGIN);
  const [showSignup, setShowSignup] = useState(false);
  const isMobile = useIsMobile(); // Use the custom hook

  const onFinish = async (values) => {
    try {
      const mutationResponse = await login({
        variables: { email: values.email, password: values.password },
      });
      const token = mutationResponse.data.login.token;
      Auth.login(token);
    } catch (e) {
      console.log(e);
      notification.error({
        message: 'Login Failed',
        description: 'Incorrect email or password.',
        placement: 'topLeft',
        duration: 10,
      });
    }
  };

  return (
    <div style={{ height: '100vh', }}>

      {isMobile ? (
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#001529' }}>
          <div style={{ color: 'white', padding: '20px', textAlign: 'center' }}>
            <a href="https://hiremize.com" target="_blank" rel="noopener noreferrer">
              <img src={logo} alt="HireMize" style={{ marginBottom: '5px' }} />
            </a>
            <h2 style={{ color: '#fff', fontWeight: 'bold' }}>Sign In</h2>
            <p style={{ fontSize: '14px' }}>Welcome to HireMize! Please enter your details below to sign in.</p>
            <Form
              form={form}
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item
                label={<span style={{ color: '#fff' }}>Email</span>}
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                ]}
              >
                <Input prefix={<UserOutlined />} placeholder="Username" />
              </Form.Item>

              <Form.Item
                label={<span style={{ color: '#fff' }}>Password</span>}
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
              </Form.Item>

              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox><span style={{ color: '#fff' }}>Remember me</span></Checkbox>
                </Form.Item>
                {/* 
            <a className="login-form-forgot" href="">
              Forgot password
            </a> */}
              </Form.Item>

              <Form.Item>
                <Button style={{ width: '100%' }} type="primary" className='button-color' htmlType="submit" >
                  Log in
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="link">
                  <Link to="/signup">
                    Don't have an account?
                  </Link>
                  <div><Link style={{ color: '#fff' }} to="/signup">Sign Up Now</Link> </div>
                </Button>
              </Form.Item>

            </Form>
            {showSignup && (
              <div className="signup-container">
                <Signup />
              </div>
            )}
          </div>
        </div>
      ) : (


        <Layout style={{ background: '#fff' }}>
          <Sider className="sider" width={600} style={{ color: '#fff', marginRight: '10px', position: 'fixed', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              {/* <Link to="/signup">← Go to Signup</Link> */}
              <div>
                <a href="https://hiremize.com" target="_blank" rel="noopener noreferrer">
                  <img src={logo} alt="HireMize" style={{ marginBottom: '5px' }} />
                </a>
              </div>

              <h2 style={{ color: '#fff', fontWeight: 'bold' }}>Sign In</h2>
              <p style={{ fontSize: '14px' }}>Welcome to HireMize! Please enter your details below to sign in.</p>
              <Form
                form={form}
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                style={{
                  marginLeft: '35%',
                  maxWidth: 600,
                  color: '#fff'
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  label={<span style={{ color: '#fff' }}>Email</span>}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email!',
                    },
                  ]}
                >
                  <Input prefix={<UserOutlined />} placeholder="Username" />
                </Form.Item>

                <Form.Item
                  label={<span style={{ color: '#fff' }}>Password</span>}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
                </Form.Item>

                <Form.Item>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox><span style={{ color: '#fff' }}>Remember me</span></Checkbox>
                  </Form.Item>
                  {/* 
                <a className="login-form-forgot" href="">
                  Forgot password
                </a> */}
                </Form.Item>

                <Form.Item>
                  <Button style={{ width: '100%' }} type="primary" className='button-color' htmlType="submit" >
                    Log in
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button type="link">
                    <Link to="/signup">
                      Don't have an account?
                    </Link>
                    <div><Link style={{ color: '#fff' }} to="/signup">Sign Up Now</Link> </div>
                  </Button>
                </Form.Item>

                {/* Forgot My Password */}
                <Form.Item>
                  <a
                    href="/forgot-password"
                    style={{ color: '#fff' }}
                  >
                    Forgot your password?
                  </a>
                </Form.Item>

              </Form>
              {showSignup ?
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                  <Signup style={{ alignSelf: 'center' }} />
                </div>
                : null}
            </div>

          </Sider>
          <Content className='content'>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <h2 style={{ marginTop: '10px', marginBottom: '5px', zIndex: '1', fontWeight: 'bold', fontSize: '2em', color: '#333' }}>AI Resume & Cover Letter Creator</h2>
              <p style={{ zIndex: '1', color: '#333' }}>Optimize your resume and cover letter with a single click. Ensure your application is friendly to automated resume screening systems.</p>
              <ReactPlayer url='/Clips/OptimizeResume.mp4' muted={true} controls={false} playing={true} loop={true} width='905' height='95%' />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <h2 style={{ marginTop: '10px', marginBottom: '5px', zIndex: '1', fontWeight: 'bold', fontSize: '2em', color: '#333' }}>Manage Your Applications</h2>
              <p style={{ zIndex: '1', color: '#333' }}>Manage your job applications. Receive customized tasks for each stage, from resume enhancement to interview and negotiation strategies.</p>
              <ReactPlayer url='/Clips/Applications.mp4' muted={true} controls={false} playing={true} loop={true} width='95%' height='95%' />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <h2 style={{ marginTop: '10px', marginBottom: '5px', zIndex: '1', fontWeight: 'bold', fontSize: '2em', color: '#333' }}>Automate Your Networking</h2>
              <p style={{ zIndex: '1', color: '#333' }}>Compose compelling messages and discover valuable contacts. Enhance your visibility and boost your prospects of securing the job.</p>
              <ReactPlayer url='/Clips/Network.mp4' muted={true} controls={false} playing={true} loop={true} width='95%' height='95%' />
            </div>
          </Content>
        </Layout>
      )}
    </div>
  );
}

export default Login;
