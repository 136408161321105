import React, { useState, useEffect } from 'react';
import { Card, Space, Typography, Button, Row, Col, Select, notification, Spin  } from 'antd';
import { useQuery } from '@apollo/client';
import { QUERY_PUBLIC_COMPANIES } from '../../../../../utils/queries';
import { useMutation } from '@apollo/client';
import { ADD_KEYWORDS_TO_USER } from '../../../../../utils/mutations';
import { REMOVE_KEYWORDS_FROM_USER } from '../../../../../utils/mutations';
import { QUERY_ALL_JOB_FIELDS } from '../../../../../utils/queries';

const { Title, Text } = Typography;


const TargetCompanies = ({ jobData, userData, setUserData, setStep, disableNext }) => {
    const [selectedCompanies, setSelectedCompanies] = useState(userData.targetCompanies ? userData.targetCompanies : []);
    const { data: companyData } = useQuery(QUERY_PUBLIC_COMPANIES);
    const [loading, setLoading] = useState(true);
    const [companies, setCompanies] = useState([]);
    const [addKeywordsToUser] = useMutation(ADD_KEYWORDS_TO_USER);
    const [removeKeywordsFromUser] = useMutation(REMOVE_KEYWORDS_FROM_USER);
    const { data: jobFieldsData } = useQuery(QUERY_ALL_JOB_FIELDS);
    const [filterOptions, setFilterOptions] = useState([]);
    const [selectedJobField, setSelectedJobField] = useState('');

    useEffect(() => {
        if (userData.targetJob) {
            const jobField = jobData.allJobs.find(job => {
                const isMatch = job._id === userData.targetJob._id
                return isMatch;
            });

            if (jobField) {
                setSelectedJobField(jobField.jobFields._id);
            }

        }
    }, [userData.targetJob, jobData.allJobs]);

    useEffect(() => {
        if (companyData) {
            setCompanies(companyData.publicCompanies);
            setLoading(false);
        }
    }, [companyData]);

    useEffect(() => {
        if (jobFieldsData) {

            const options = jobFieldsData.allJobFields.map(field => ({
                id: field._id,
                name: field.Field
            }));
            setFilterOptions(options);
        }
    }, [jobFieldsData]);

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spin size="large" tip="Loading..." />
            </div>
        );
    }

    const handleCompanySelect = (company) => {
        if (selectedCompanies.length >= 5) {
            notification.warning({
                message: 'Selection Limit Reached',
                description: 'You can only select up to 5 companies.',
            });
            return;
        }
        setSelectedCompanies(prevCompanies => [...prevCompanies, company._id]);
        setUserData({
            ...userData,
            targetCompanies: [...selectedCompanies, company._id],
        })
        disableNext(false);

        addKeywordsToUser({
            variables: {
                keywords: company.keywords,
            },
        });

    }

    const handleCompanyDeselect = (company) => {

        setSelectedCompanies(prevCompanies => prevCompanies.filter(c => c !== company._id));
        setUserData({
            ...userData,
            targetCompanies: selectedCompanies.filter(c => c !== company._id),
        })
        if (selectedCompanies.length === 1) {
            disableNext(true);
        }

        removeKeywordsFromUser({
            variables: {
                keywords: company.keywords,
            },
        });
    }

    // const jobFields = [
    //     { Field: 'Engineering' },
    //     { Field: 'Business' },
    //     { Field: 'Health & Medicine' },
    //     { Field: 'Math & Technology' },
    //     { Field: 'Law' },
    //     { Field: 'Arts & Communication' },
    //     { Field: 'Social Sciences' },
    //   ];

    // const jobFields = [...new Set(companies.map(company => company.jobFields))];



    const handleSelectJobField = (value) => {
        console.log(value);
        setSelectedJobField(value);
    };

    const filteredCompanies = selectedJobField ? companies.filter(company => {
        const hasField = company.jobFields.some(field => field._id === selectedJobField);
        return hasField;
    }) : companies;

    // If no companies match the filter, return all companies
    const resultCompanies = filteredCompanies.length > 0 ? filteredCompanies : companies;





    return (
        <>
            <Title level={3}>Select Your Target Companies (Optional)</Title>
            <Select
                placeholder="Filter"
                style={{ width: '50%' }}
                onChange={handleSelectJobField}
            >
                {filterOptions.map((field, index) => (
                    <Select.Option key={index} value={field.id}>{field.name}</Select.Option>
                ))}
            </Select>
            <Card style={{ margin: '20px', borderRadius: '15px', padding: '15px', maxHeight: '55vh', overflowY: 'auto' }}>
                <Space direction="vertical" size="large">

                    <Row gutter={[16, 16]} justify="center" align="middle" >
                        {resultCompanies.map((company) => (
                            <Col xs={24} sm={12} md={8} lg={6} key={company._id} style={{maxWidth:'100%'}}>
                                <Card
                                    key={selectedCompanies.includes(company._id) ? 'selected' : 'not-selected'}
                                    hoverable
                                    style={{
                                        width: 240,
                                        borderColor: selectedCompanies.includes(company._id) ? 'green' : 'default'
                                    }}
                                    onClick={() => {
                                        if (selectedCompanies.includes(company._id)) {
                                            handleCompanyDeselect(company);
                                        } else {
                                            handleCompanySelect(company);
                                        }
                                    }}
                                    cover={
                                        <div style={{
                                            height: '100px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            overflow: 'hidden'
                                        }}>
                                            <img
                                                src={company.logo}
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevents infinite loop if default logo also fails
                                                    e.target.src = '/images/company-default.png';
                                                }}
                    
                                                alt="example"
                                                style={{
                                                    maxHeight: '100%',
                                                    maxWidth: '100%',
                                                    objectFit: 'contain'
                                                }}
                                            />
                                        </div>
                                    }
                                >
                                    <Card.Meta title={company.companyName} />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Space>
            </Card>
        </>
    );
}

export default TargetCompanies;