import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, Input, Typography, Spin, Dropdown, Menu, Space, Checkbox, Tooltip, notification, Row, Col, List } from 'antd';
import { DownOutlined, LinkedinOutlined, PhoneOutlined, MailOutlined, LinkOutlined, CopyTwoTone } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { UPDATE_APPLICANT_LAST_CONTACTED } from '../../../utils/mutations';
import EmailCompose from '../../MailBox/EmailComposer/index.js'
import { fetchSendEmail } from '../../../utils/EmailAPIs/sendEmail.js';
import { UserContext } from '../../../contexts/userContext.js'

const { Title, Text } = Typography;

const BroadCastMessageModal = ({ selectedIds, selectContactEmailData, onClose }) => {

    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [newSubject, setNewSubject] = useState("");
    const [message, setMessage] = useState("");

    const [updateApplicantLastContacted] = useMutation(UPDATE_APPLICANT_LAST_CONTACTED);

    console.log("selectedIds", selectedIds);
    console.log("selectContactEmailData", selectContactEmailData);


    const handleSendMessage = async () => {
        setLoading(true);
        try {

            const response = await fetchSendEmail(selectContactEmailData, user.customUserEmail, newSubject, message, selectContactEmailData, user._id);

            // Check if the response is an error
            if (response instanceof Error || !response) {
                console.log("Error sending email:", response);
                throw response;
            }

            // Update the last contacted date for each selected contact
            await Promise.all(
                selectedIds.map(async (appId) => {
                    await updateApplicantLastContacted({
                        variables: {
                            id: appId,
                            applicantLastContacted: new Date().toISOString().slice(0, 10),
                        },
                    });
                })
            );
            notification.success({
                message: 'Success',
                description: 'Message sent successfully.',
            });
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to send message.',
            });
        } finally {
            setLoading(false);
            onClose();
        }
    }

    const handleCancel = () => {
        onClose();
    }

    return (
        <Modal
            title="Broadcast Message"
            visible={true}
            onCancel={handleCancel}
            footer={null}
            width={600}
        >



            <EmailCompose
                newSubject={newSubject}
                setNewSubject={setNewSubject}
                newBody={message}
                setNewBody={setMessage}
                messageRows={8} />

            <Spin spinning={loading}>
                <Row justify="end">
                    <Button type="primary" onClick={handleSendMessage} disabled={!message}>
                        Send Message
                    </Button>
                </Row>
            </Spin>

        </Modal>
    );

}

export default BroadCastMessageModal;