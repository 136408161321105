import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { RESET_PASSWORD } from '../../utils/mutations';
import { Form, Input, Button, notification } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const { token } = useParams(); // Get the token from the URL
  const [resetPassword] = useMutation(RESET_PASSWORD);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Hook for navigation

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await resetPassword({
        variables: {
          token,
          password: values.password,
        },
      });
      notification.success({
        message: 'Password Reset Successful',
        description: 'Your password has been reset. You can now log in.',
        placement: 'topLeft',
        duration: 10,
      });
    } catch (e) {
      console.error(e);
      notification.error({
        message: 'Error',
        description: 'Failed to reset password. Please try again.',
        placement: 'topLeft',
        duration: 10,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: '400px', margin: '50px auto', textAlign: 'center' }}>
      <h2>Reset Password</h2>
      <p>Enter your new password below.</p>
      <Form onFinish={handleSubmit} layout="vertical">
        <Form.Item
          label="New Password"
          name="password"
          rules={[
            { required: true, message: 'Please enter your new password!' },
            { min: 8, message: 'Password must be at least 8 characters long!' },
          ]}
        >
          <Input.Password placeholder="Enter your new password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Reset Password
          </Button>
        </Form.Item>
      </Form>
      <Button type="link" onClick={() => navigate('/login')}>
        Back to Login
      </Button>
    </div>
  );
};

export default ResetPassword;