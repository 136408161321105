import React, { useEffect, useState, useContext } from "react";
import Auth from "../../utils/auth";
import { Link } from "react-router-dom";
import { Menu, Button, Typography } from "antd";
//import "./PageNav.css";
import logo from '../../assets/logo.png';
import { HomeOutlined, FileSearchOutlined, FileDoneOutlined, ContactsOutlined, UserOutlined, LogoutOutlined, QuestionCircleOutlined, ExclamationCircleFilled, FileTextOutlined, AuditOutlined, ContainerOutlined, InboxOutlined, FireOutlined, SearchOutlined, KeyOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { QUERY_MY_APPLICATIONS } from '../../utils/queries';
import { FeatureFlagsContext } from '../../contexts/FeatureFlagsContext';
import GuideModal from '../Modals/GuideModal';
import NewHelpModal from '../Modals/NewHelpModal';
import { UserContext } from "../../contexts/userContext";

const { SubMenu, Item } = Menu;
const { Title } = Typography;

const menuStyle = { color: '#2ca4cc', fontWeight: 'bold' };

function PageNav(collapsedSidebar) {
  const { data, loading, refetch } = useQuery(QUERY_MY_APPLICATIONS);
  const [addFirstApp, setAddFirstApp] = useState(false);
  const featureFlags = useContext(FeatureFlagsContext);
  const [openGuide, setOpenGuide] = useState(false);
  const { userRole } = useContext(UserContext);

  const handleLogout = () => {
    Auth.logout();
  };

  //console.log(collapsedSidebar);

  useEffect(() => {
    if (data && (!data.myApplications || data.myApplications.length === 0)) {
      setAddFirstApp(true);
    }
  }, [data]);

  return (
    <div style={{ height: 'calc(100vh - 55px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <div>
        <h1 className="Hiremize-Title">
          <Link to="/" onClick={(event) => {
            event.preventDefault();
            window.location.href = '/';
          }}>
            {collapsedSidebar.collapsedSidebar ?
              <span style={{ color: '#2ca4cc', padding: '10px' }}>H</span> :
              <img src={logo} alt="HireMize" />
            }
          </Link>
        </h1>
      </div>
      <div>
        {/* <Title level={5} style={{ textAlign: 'center', color: '#2ca4cc' }}>Welcome to our beta</Title> */}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
        <Menu
          mode="inline"
          theme="dark"
          style={{ flex: 1, }} //color: '#2ca4cc'
        // className="nav-menu"
        // breakpoint="lg"
        >

          {userRole === 'jobSeeker' ? (
            // Job Seeker Navigation
            <>
              {/* {addFirstApp ? (
                <Item key='first app' icon={collapsedSidebar.collapsedSidebar ? <ExclamationCircleFilled style={{ color: 'yellow' }} /> : null}>
                  {collapsedSidebar.collapsedSidebar ?
                    <Link to="/applications" onClick={(event) => {
                      event.preventDefault();
                      window.location.href = '/applications';
                    }} >  Add First Application </Link> :
                    <li className="mx-1">
                      <Link to="/applications" onClick={(event) => {
                        event.preventDefault();
                        window.location.href = '/applications';
                      }}>
                        <Button className="button-pulse">Add First Application</Button>
                      </Link>
                    </li>
                  }
                </Item>
              ) : null} */}

              {featureFlags.pageNav.home && (
                <Item key="home" icon={<HomeOutlined />} style={menuStyle}>
                  <Link to="/">Home</Link>
                </Item>
              )}
              {featureFlags.pageNav.opportunities && (
                <SubMenu key="Opportunities" icon={<KeyOutlined style={{ color: '#2ca4cc' }} />} title={<span style={menuStyle} >Opportunities</span>} style={menuStyle}>
                  <Item key="matcher" icon={<FireOutlined />} style={menuStyle}>
                    <Link to="/matcher">Matcher</Link>
                  </Item>
                  <Item key="browse" icon={<SearchOutlined />} style={menuStyle}>
                    <Link to="/find-jobs">Browse</Link>
                  </Item>
                </SubMenu>
              )}
              {featureFlags.pageNav.contacts && (
                <SubMenu key="Network" icon={<FileSearchOutlined style={{ color: '#2ca4cc' }} />} title={<span style={menuStyle} >Network</span>} style={menuStyle}>
                  <Item key="contacts" icon={<ContactsOutlined />} style={menuStyle}>
                    <Link to="/contacts">Contacts</Link>
                  </Item>
                  <Item key="inbox" icon={<InboxOutlined />} style={menuStyle}>
                    <Link to="/inbox">Inbox</Link>
                  </Item>
                </SubMenu>
              )}
              <Item key="Resumes" icon={<FileTextOutlined />} style={menuStyle}>
                <Link to="/resumes">Resumes</Link>
              </Item>
              {featureFlags.pageNav.applications && (
                <Item key="applications" icon={<ContainerOutlined />} style={menuStyle}>
                  <Link to="/applications">Applications</Link>
                </Item>
              )}
            </>
          ) : (
            // Company Navigation
            <>
              <Item key="home" icon={<HomeOutlined />} style={menuStyle}>
                <Link to="/">Home</Link>
              </Item>
              <Item key="inbox" icon={<InboxOutlined />} style={menuStyle}>
                <Link to="/inbox">Inbox</Link>
              </Item>
            </>
          )}

        </Menu>

        <div>
          <Title level={5} style={{ textAlign: 'center', color: '#2ca4cc', marginLeft: '15px' }}></Title>
          <Menu
            mode="inline"
            theme="dark"
          // className="nav-menu"
          // breakpoint="lg"
          >

            <Item key="help" onClick={collapsedSidebar.collapsedSidebar ? (() => setOpenGuide(true)) : null} icon={collapsedSidebar.collapsedSidebar ? < QuestionCircleOutlined /> : null}>
              {collapsedSidebar.collapsedSidebar ?
                'Help' :
                <Button
                  className="button-color-premium"
                  style={{ width: '100%' }}
                  type="primary"
                  icon={<QuestionCircleOutlined />}
                  onClick={() => setOpenGuide(true)}
                >
                  Help
                </Button>
              }
            </Item>

            <Item key="profile" icon={collapsedSidebar.collapsedSidebar ? <UserOutlined /> : null}>
              {collapsedSidebar.collapsedSidebar ?
                <Link to="/profile"> Profile </Link> :

                <Button
                  className="button-color"
                  style={{ width: '100%' }}
                  type="primary"
                  icon={<UserOutlined />}
                >
                  <Link to="/profile"> Profile </Link>
                </Button>
              }
            </Item>

            <Item key="logout" onClick={collapsedSidebar.collapsedSidebar && handleLogout} icon={collapsedSidebar.collapsedSidebar && <LogoutOutlined />}>
              {collapsedSidebar.collapsedSidebar ?
                'Logout' :
                <Button
                  className="button-color"
                  style={{ width: '100%' }}
                  type="primary"
                  icon={<LogoutOutlined />}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              }
            </Item>

          </Menu>
          {openGuide && <NewHelpModal visible onClose={() => setOpenGuide(false)} />}
        </div>
      </div>
    </div>
  );
}
export default PageNav;


