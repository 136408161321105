import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Auth from '../../utils/auth';
import { ADD_USER } from '../../utils/mutations';
import { Form, Input, Button, Layout, notification } from 'antd';
import './SignUpComponent.css'; // Import the CSS file

const SignUpComponent = ({ jobId }) => {
    const [form] = Form.useForm();
    const [addUser, { error }] = useMutation(ADD_USER);
    const [formState, setFormState] = useState({ email: '', password: '', firstName: '', lastName: '' });

    const handleFormSubmit = async (values) => {
        try {
            const mutationResponse = await addUser({
                variables: {
                    email: values.email,
                    password: values.password,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    signUpWithJob: jobId
                },
            });
            const token = mutationResponse.data.addUser.token;
            Auth.login(token);
        } catch (e) {
            console.log(e);
            // Check for specific error types
            if (e.message.includes('User validation failed')) {
                // Handle validation errors (e.g., password too short)
                notification.error({
                    message: 'Signup Failed',
                    description: 'Password must be at least 8 characters long.',
                    placement: 'topLeft',
                    duration: 10,
                });
            } else if (e.message.includes('E11000 duplicate key error')) {
                // Handle duplicate email error
                notification.error({
                    message: 'Signup Failed',
                    description: 'The email address is already in use. Please use a different email or log-in.',
                    placement: 'topLeft',
                    duration: 10,
                });
            } else {
                // Handle generic errors
                notification.error({
                    message: 'Signup Failed',
                    description: 'An unexpected error occurred. Please try again later.',
                    placement: 'topLeft',
                    duration: 10,
                });
            }
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormState({
            ...formState,
            [name]: value,
        });
    };

    return (


        <div className="site-layout-content">
            <p>Create Account</p>
            <Form
                form={form}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={handleFormSubmit}
                className="signup-form"
                layout="vertical"
            >
                <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[{ required: true, message: 'Please input your first name!' }]}
                >
                    <Input
                        name="firstName"
                        type="text"
                        id="firstName"
                        onChange={handleChange}
                        className="signup-input"
                    />
                </Form.Item>

                <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[{ required: true, message: 'Please input your last name!' }]}
                >
                    <Input
                        name="lastName"
                        type="text"
                        id="lastName"
                        onChange={handleChange}
                        className="signup-input"
                    />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' }]}
                >
                    <Input
                        name="email"
                        type="email"
                        id="email"
                        onChange={handleChange}
                        className="signup-input"
                    />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password
                        name="password"
                        id="password"
                        onChange={handleChange}
                        className="signup-input"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="button-color">
                        Upload Resume
                    </Button>
                </Form.Item>
            </Form>
            {error && <div>Sign up failed</div>}
            <p>Already have an account?</p>
            <Link to="/login">← Go to Login</Link>
        </div>

    );
}

export default SignUpComponent;