import React from 'react';
import { Modal, Typography, Button, Divider, Card } from 'antd';
import JobMatchButtons from '../../../../assets/MatchCardButtons/JobMatchButtons.png';
import NotInterestedButton from '../../../../assets/MatchCardButtons/NotInterestedButton.png';

const { Title, Text } = Typography;

const JobPostingPopUpModal = ({ visible, onClose }) => {
    return (
        <Modal
            visible={visible}
            footer={null}
            width={800}
            centered
            destroyOnClose
            closable={false}
        >
            <Title level={2}>Job Matched!</Title>
            <Card style={{ backgroundColor: '#fcdc5c', borderRadius: '8px', textAlign: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Text style={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}>
                        We have evaluated your profile and found an active job posting that matches your skills.
                    </Text>
                    <Text style={{ fontSize: '12px', color: 'black', marginTop: '10px' }}>
                        Our system aims to match you with the best job postings based on your profile.
                    </Text>
                </div>
            </Card>
            <Divider />
            <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
                    <img src={JobMatchButtons} style={{ marginLeft: '20px', marginRight: '10px' }} />
                    <Text style={{ marginLeft: '10px' }}>
                        Click "Save" to <strong>add this job</strong> to your applications.
                        Click "Auto Apply" to start the <strong>automatic application process.</strong>
                    </Text>
                </div>
                <Divider />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
                    <Text>You can also click "Not Interested"</Text>
                    <img src={NotInterestedButton} style={{ marginLeft: '20px' }} />
                </div>
            </div>
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                <Button className='button-color' style={{ paddingRight: '50px', paddingLeft: '50px' }} type="primary" onClick={onClose}>
                    Got it!
                </Button>
            </div>
        </Modal>
    );
}

export default JobPostingPopUpModal;