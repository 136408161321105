import React, { useEffect, useState, useContext } from 'react';
import JobPostingItem from '../../components/Cards/JobPostingItem/index.js';
import ContactItem from '../../components/Cards/ContactItem/index.js';
import CompanyItem from '../../components/Cards/CompanyItem/index.js';
import LocationSearchBox from '../../components/SearchBoxes/LocationSearchBox/index.js';
import KeywordSearchBox from '../../components/SearchBoxes/KeywordSearchBox/index.js';
import { useQuery } from '@apollo/client';
import { QUERY_ALL_PUBLIC_JOB_POSTINGS } from '../../utils/queries.js';
import { QUERY_PUBLIC_CONTACTS } from '../../utils/queries.js'
import { QUERY_PUBLIC_COMPANIES } from '../../utils/queries.js'
import { QUERY_JOB_POSTINGS_BY_CATEGORY } from '../../utils/queries.js';
import { Row, Col, Input, Button, Typography, Empty, Pagination } from 'antd';
import './Opportunities.css';
import { UserContext } from '../../contexts/userContext.js';


import { useLocation } from 'react-router-dom';
const { Title } = Typography;

const OpportunitiesList = ({ category }) => {
  const location = useLocation();

  console.log(category);

  const { loading, error, data, refetch } = useQuery(
    category ? QUERY_JOB_POSTINGS_BY_CATEGORY : QUERY_ALL_PUBLIC_JOB_POSTINGS,
    {
      variables: category ? { category } : {},
      skip: !category && category !== '', // Skip the query if category is not empty
    }
  );

  const [jobPostings, setJobPostings] = useState([]);
  const [filteredJobPostings, setFilteredJobPostings] = useState([]);
  const { data: contactData } = useQuery(QUERY_PUBLIC_CONTACTS);
  const [contacts, setContacts] = useState([]);
  const { data: companyData } = useQuery(QUERY_PUBLIC_COMPANIES);
  const [companies, setCompanies] = useState([]);
  const { user } = useContext(UserContext);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12); // Number of items per page

  const [searchTerms, setSearchTerms] = useState({ keyword: '', location: '' });

  useEffect(() => {
    if (location.pathname === '/find-jobs') {
      refetch();
    }
  }, [location.pathname, refetch]);

  useEffect(() => {
    if (data && user && user._id) {

      var jobPostings
      if (!category) {
        jobPostings = data.publicJobPostings.filter(job =>
          job.recommendedTo.some(recUser => recUser._id === user._id)
        );
      } else {
        jobPostings = data.findJobPostingsInCategory
      }

      setJobPostings(jobPostings);
      setFilteredJobPostings(jobPostings);
    }
    if (contactData && user && user._id) {
      setContacts(
        contactData.publicContacts.filter(contact =>
          contact.recommendedTo.some(recUser => recUser._id === user._id)
        )
      );
    }
    if (companyData && user && user._id) {
      setCompanies(
        companyData.publicCompanies.filter(company =>
          company.recommendedTo.some(recUser => recUser && recUser._id === user._id)
        )
      );
    }
  }, [data, contactData, companyData, category]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // Calculate the current page's job postings
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentJobPostings = filteredJobPostings.slice(startIndex, endIndex);


  const handleSearch = () => {
    // Combine keyword and location to perform a search
    const { keyword, location } = searchTerms;

    // Validate and format the location input
    const formattedLocation = formatLocation(location);

    const newFilteredJobPostings = jobPostings.filter((job) =>
      job.position.toLowerCase().includes(keyword.toLowerCase()) &&
      job.location.toLowerCase().includes(formattedLocation.toLowerCase())
    );

    setFilteredJobPostings(newFilteredJobPostings);

    //setSearchTerms({location: ' '}) - Maybe Not Neccessary

    // Handle filteredJobPostings (e.g., display results or take further action)
    console.log(keyword, formattedLocation, newFilteredJobPostings);
  };

  // Function to format the location input
  const formatLocation = (inputLocation) => {
    // Split the input by commas and trim whitespace
    const parts = inputLocation.split(',').map((part) => part.trim());

    // Check if the input has at least two parts (City and Province/State)
    if (parts.length >= 2) {
      // Capitalize the first letter of each part and join them with a comma
      return parts.map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join(', ');
    } else {
      // Invalid input format, return the original input
      return inputLocation;
    }
  };

  const handleAllJobs = () => {
    setFilteredJobPostings(jobPostings);
  }

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };


  return (
    <div>
      {/* Keyword and Location Input Fields on the same row */}

      {/* <div className="search-box">
        <KeywordSearchBox
          onSearch={(keyword) => {
            setSearchTerms((prevSearchTerms) => ({ ...prevSearchTerms, keyword }));
          }}
        />
        <LocationSearchBox onSearch={(location) => setSearchTerms({ ...searchTerms, location })} />

        <Button type="primary" onClick={handleSearch}>
          Search
        </Button>
        <Button type="primary" onClick={handleAllJobs}>
          All Jobs
        </Button>
      </div> */}

      <Title level={2} style={{ textAlign: 'center' }}>{category}</Title>

      <Row gutter={[16, 16]}>
        {currentJobPostings && currentJobPostings.length > 0 ? (
          currentJobPostings.map((job, index) => (
            <Col xs={24} sm={12} md={8} lg={8} xl={8} key={index}>
              <JobPostingItem job={job} />
            </Col>
          ))
        ) : (
          <div justify="center" align="middle" style={{ width: '100%', paddingTop: '2.5%' }}>
            <Empty description="No job postings found for this category. Please check back later!" />
          </div>
        )}
        {/* {contacts.map((contact, index) => (
          <Col xs={24} sm={12} md={8} lg={8} xl={8} key={index}>
            <ContactItem contact={contact} />
          </Col>
        ))} */}
        {/* {companies.map((company, index) => (
          <Col xs={24} sm={12} md={8} lg={8} xl={8} key={index}>
            <CompanyItem company={company} />
          </Col>
        ))} */}
      </Row>
      {/* Pagination Controls */}
      {currentJobPostings && currentJobPostings.length > 0 &&
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={filteredJobPostings.length}
            onChange={handlePageChange}
            showSizeChanger
            pageSizeOptions={[6, 12, 24, 48]} // Options for items per page
          />
        </div>
      }
    </div>
  );
};

export default OpportunitiesList;


