import React, { useState, useEffect, useContext} from 'react';
import { Button, Modal } from 'antd';
import EditResume from '../../../../../pages/EditResume/EditResume'
import { UserContext } from '../../../../../contexts/userContext';


const EditResumeModal = ({ visible, onClose, resumeID }) => {
    const { user, myResumeData, refetchUser } = useContext(UserContext);
    const [resume, setResume] = useState(resumeID);

    refetchUser();

    useEffect(() => {
        if (myResumeData && !resumeID) {
            setResume(user.myResumeData._id);
        }
    }, [myResumeData, resumeID, user]);

    const contentStyle = {
        height: '100%',
    };

    return (
        <>
        <Modal
            title=" "
            centered
            visible={visible}
            onCancel={onClose}
            closable={false}
            footer={null}
            width={'100%'}
            bodyStyle={contentStyle}
        >
            <EditResume resumeId={resume} closeModal={onClose}/>
        </Modal>

        
        </>

    );

};

export default EditResumeModal;
