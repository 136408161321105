import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../contexts/userContext';
import Auth from '../utils/auth';

const ProtectedRoute = ({ component: Component, allowedRoles, fallbackComponent: FallbackComponent }) => {
    const { userRole } = useContext(UserContext);

    if (!Auth.loggedIn()) {
        return <Navigate to="/login" />;
    }

    if (!allowedRoles.includes(userRole)) {

        if (FallbackComponent) {
            return <FallbackComponent />;
        } else {
            return <Navigate to="/" />;
        }
    }

    return <Component />;
};

export default ProtectedRoute;