import React, { useEffect, useContext, useState } from 'react';
import { Card, Space, Typography, Upload, Button, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { startParseResumeTask, getParseResumeTaskStatus, getParseResumeTaskResult } from '../../../../../utils/CreateResumeAPIs/resParse';
import { useMutation } from "@apollo/client";
import { ADD_RESUME_DATA } from "../../../../../utils/mutations";
import { ADD_MY_RESUME_DATA } from "../../../../../utils/mutations";
import { backgroundJobCheck } from '../../../../../utils/helpers/background';
import { UserContext } from '../../../../../contexts/userContext';
import './UploadResume.css';
import NoResumeDataModal from '../../../NoResumeDataModal';
import EditResumeModal from './EditResumeModal';
import { uploadResume } from '../../../../../utils/StoredResumeAPIs';

const { Dragger } = Upload;

const close = () => {
    console.log(
        'Notification was closed. Either the close button was clicked or duration time elapsed.',
    );
};



const UploadResume = ({ setStep, disableNext, signUpJob }) => {

    useEffect(() => {
        disableNext(true);
    }, []);

    const [addResumeData, { error }] = useMutation(ADD_RESUME_DATA);
    const [addMyResumeData] = useMutation(ADD_MY_RESUME_DATA);
    const [api, contextHolder] = notification.useNotification();
    const { refetchUser, user } = useContext(UserContext);
    const [noResumeDataModal, setNoResumeDataModal] = useState(false);
    const [showProceedManually, setShowProceedManually] = useState(true);
    const [showEditResumeModal, setShowEditResumeModal] = useState(false);

    useEffect(() => {
        if (user && user.myResumeData) {
            setShowProceedManually(false);
            disableNext(false)
        }
    }, [user])

    const openNotificationWithIcon = (type, mes, description) => {
        const key = `open${Date.now()}`;
        const btn = (
            <Space>
                <Button type="primary" size="small" onClick={() => api.destroy(key)}>
                    Got It!
                </Button>
            </Space>
        );
        api[type]({
            message: mes,
            description: description,
            duration: 0,
            btn,
            key,
            onClose: close,
        });
    };

    const handleParse = async (file) => {

        try {
            // ---Upload the resume file to the server---
            try {
                await uploadResume(user._id, file);
            } catch (error) {
                console.error("There was a problem uploading your resume", error.message);
            }


            // ---Start the resume parsing task and get a task ID---
            let taskResponse = await startParseResumeTask(file);
            let taskId = taskResponse.data.taskID;
            // Check the status of the task and get the result
            let response = await backgroundJobCheck(getParseResumeTaskStatus, getParseResumeTaskResult, taskId);
            let parsedResumeData = response.result;

            //Send Resume Data to DB
            const { data } = await addResumeData({ variables: { mainData: parsedResumeData, dataType: 'Basic Parse' } });
            //Link Resume Data to User's DB
            await addMyResumeData({ variables: { myResumeData: data.addResumeData._id } });

            refetchUser()

            console.log("Resume Data", data.addResumeData);

            if (!signUpJob) {
                openNotificationWithIcon('success', 'Autofill Complete!', (
                    <>
                        Your Resume has been successfully parsed. You can now proceed to the next step.
                    </>
                ))
            }

            if (signUpJob) {
                setShowEditResumeModal(true)
            }

            disableNext(false)
            setShowProceedManually(false)

        } catch (error) {
            openNotificationWithIcon('error', 'Error Parsing Resume', 'Please try again, if the problem persists contact support or proceed to create your resume from scratch.')
            console.error("There was a problem parsing your resume", error.message);
        }
    }

    const props = {
        name: 'file',
        multiple: true,
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        onChange: async (info) => {
            const { status } = info.file;
            if (status !== 'uploading') {
                handleParse(info.file.originFileObj);
            }
        },

        style: { height: '100px' },
    };


    return (
        <div className="upload-resume-container">
            <Space>{contextHolder}</Space>
            {!signUpJob && (
                <p className="mission-statement">
                    Our mission is to streamline the application process and to help you find quality opportunities
                </p>
            )}

            <h3 className="get-started">Let's get started!</h3>

            <div className="upload-resume-content">
                <div className="dragger-container">
                    <Dragger {...props}>
                        <p className="dragger-text">Upload your existing resume to autofill your work experience</p>
                        <p className="dragger-icon">
                            <UploadOutlined />
                        </p>
                        <p className="dragger-instruction">Click or drag file to this area to upload</p>
                    </Dragger>
                </div>

                {!signUpJob ? (
                    <div className="manual-button-container">
                        {showProceedManually ? (
                            <Button className='button-color' onClick={() => setNoResumeDataModal(true)} type="primary">Proceed Manually</Button>
                        ) : (
                            <div className="manual-button-container">
                                <p>Your Resume has been uploaded!</p>
                                <Button className='button-color-premium' onClick={() => setStep(1)} type="primary">Continue</Button>
                            </div>
                        )}
                    </div>
                ) : (
                    showProceedManually ? (
                        <div className="manual-button-container">
                            <p>Upload your resume to autofill your application</p>
                        </div>
                    ) : (
                        <div className="manual-button-container">
                            <p>Resume uploaded successfully!</p>
                            <Button className='button-color' onClick={() => setShowEditResumeModal(true)} type="primary">Edit Resume</Button>
                            <p style={{ marginTop: '2.5%' }}>or</p>
                            <Button className='button-color-premium' onClick={() => setStep(1)} type="primary">Continue</Button>
                        </div>
                    )
                )}
            </div>
            {
                noResumeDataModal &&
                <div className="modal-center">
                    <NoResumeDataModal onClose={() => setNoResumeDataModal(false)} warning={true} nextStep={() => setStep(1)} />
                </div>
            }
            {
                showEditResumeModal &&
                <EditResumeModal visible={true} onClose={() => setShowEditResumeModal(false)} resumeID={null} />
            }
        </div >
    );
}

export default UploadResume;

