import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { SEND_PASSWORD_RESET_EMAIL } from '../../utils/mutations';
import { Form, Input, Button, notification } from 'antd';

const ForgotPassword = () => {
  const [sendPasswordResetEmail] = useMutation(SEND_PASSWORD_RESET_EMAIL);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await sendPasswordResetEmail({
        variables: { email: values.email },
      });
      notification.success({
        message: 'Email Sent',
        description: 'A password reset link has been sent to your email.',
        placement: 'topLeft',
        duration: 10,
      });
    } catch (e) {
      console.error(e);
      notification.error({
        message: 'Error',
        description: 'Failed to send password reset email. Please try again.',
        placement: 'topLeft',
        duration: 10,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: '400px', margin: '50px auto', textAlign: 'center' }}>
      <h2>Forgot Password</h2>
      <p>Enter your email address to receive a password reset link.</p>
      <Form onFinish={handleSubmit} layout="vertical">
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Please enter your email!' },
            { type: 'email', message: 'Please enter a valid email!' },
          ]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Send Reset Link
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ForgotPassword;