import React from 'react';
import { Modal, Typography, Button, Divider, Tooltip, Card } from 'antd';
import { ThunderboltFilled } from '@ant-design/icons';
import NotInterestedButton from '../../../../assets/MatchCardButtons/NotInterestedButton.png';
import ContactMatchCardButtons from '../../../../assets/MatchCardButtons/ContactMatchCardButtons.png';
import Rewrite from '../../../../assets/MatchCardButtons/Rewrite.png';

const { Title, Text } = Typography;

const CompanyPopUpModal = ({ visible, onClose }) => {
    return (
        <Modal
            visible={visible}
            footer={null}
            width={800}
            centered
            destroyOnClose
            closable={false}
        >
            <Title level={2}>Hidden Job Market!</Title>
            <Card style={{ backgroundColor: '#fcdc5c', borderRadius: '8px', textAlign: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Text style={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}>
                        Connect with company insiders to unlock the hidden job market.
                    </Text>
                    <Text style={{ fontSize: '12px', color: 'black', marginTop: '10px' }}>
                        Over <strong>50-60%</strong> of jobs are not posted online and obtained through networking.
                    </Text>
                </div>
            </Card>
            <Divider />
            <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
                    <img src={ContactMatchCardButtons} style={{ marginRight: '20px' }} />
                    <Text style={{ marginLeft: '10px' }}>
                        Click "Save for Later" to <strong>add your contacts. </strong>
                        Click "Save & Send" to instantly <strong>email the company insider</strong>.
                    </Text>
                </div>
                <Divider />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
                    <Text>Click the thunderbolt icon to <strong>customize your email</strong> to the company</Text>
                    <img src={Rewrite} style={{ marginLeft: '20px' }} />
                </div>
                <Divider />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
                    <img src={NotInterestedButton} style={{ marginRight: '20px' }} />
                    <Text>You can also click "Not Interested"</Text>
                </div>
            </div>
            <Divider />

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                <Button className='button-color' style={{ paddingRight: '50px', paddingLeft: '50px' }} type="primary" onClick={onClose}>
                    Got it!
                </Button>
            </div>
        </Modal>
    );
}

export default CompanyPopUpModal;