import React, { useState, useContext, useEffect, useRef } from "react";
import { Card, Button, Grid, Row, Col, Divider, Typography, Tag, Rate, Tooltip } from 'antd'
import JobPostingItem from '../JobPostingItem'
import { UserContext } from '../../../contexts/userContext';
import { useMutation } from "@apollo/client";
import { useQuery } from "@apollo/client";
//Not Interested Job Posting
import { REMOVE_KEYWORDS_FROM_USER } from "../../../utils/mutations";
import { ADD_KEYWORDS_TO_USER } from "../../../utils/mutations";
import { NOT_INTERESTED_JOB_POSTING } from "../../../utils/mutations";
import useIsMobile from '../../../contexts/useIsMobile';
import '../CompanyMatchCard/MatchCard.css';
import JobPostingDetailsCard from '../JobPostingItem/JobPostingDetailsCard';
import { ADD_APPLICATION } from '../../../utils/mutations';
import KeywordsScroll from '../CardComponents/KeywordScroller';
import { ADD_MATCH_LIKED } from '../../../utils/mutations';
import auth from "../../../utils/auth";
import FindConnectionsModal from '../../Modals/FindConnectionsModal';
import MessageModal from '../../Modals/MessageModal';
import ApplyModal from '../../Modals/ApplyModal';
import { QUERY_JOBS_SAVED } from '../../../utils/queries';



const { Title, Paragraph } = Typography;

const JobPostingsMatchCard = ({ match, next, forJobPostingPage }) => {
    const isMobile = useIsMobile();
    const [isExpanded, setIsExpanded] = useState(false);
    const { user } = useContext(UserContext);
    //Not Interested Job Posting
    const [remove_keywords_from_user] = useMutation(REMOVE_KEYWORDS_FROM_USER);
    const [add_keywords_to_user] = useMutation(ADD_KEYWORDS_TO_USER);
    const [addApplication, { error }] = useMutation(ADD_APPLICATION);
    const [not_interested_job_posting] = useMutation(NOT_INTERESTED_JOB_POSTING);
    const [addMatchLiked, { error: matchError }] = useMutation(ADD_MATCH_LIKED);
    const [justSaved, setJustSaved] = useState(false);
    const [findConnectionsModalVisible, setFindConnectionsModalVisible] = useState(false);
    const [applyModalVisible, setApplyModalVisible] = useState(false);

    const { loading, savedError, data, refetch } = useQuery(QUERY_JOBS_SAVED, { variables: { id: match._id } })

    useEffect(() => {
        if (data) {
            setJustSaved(data.getApplicationByJobID.success);
        }
    }, [data])

    const handleNotInterested = async () => {

        try {

            not_interested_job_posting({
                variables: {
                    id: match._id,
                    notInterestedTo: user._id
                }
            });

            // remove_keywords_from_user({
            //     variables: {
            //         keywords: match.keywords.slice(0, 20)
            //     }
            // });

            next(true);

        } catch (err) {
            console.error(err);
        }
    }

    const ensureProtocol = (url) => {
        if (!/^https?:\/\//i.test(url)) {
            return 'http://' + url;
        }
        return url;
    };

    const handleSave = async () => {

        try {
            addMatchLiked()
        } catch (err) {
            console.error(err);
        }

        //Save Job Posting To Applications
        try {
            await addApplication({
                variables: { job: match._id, status: "Saved" }
            });

        } catch (err) {
            console.error(err);
        }

        //Add Keywords to User
        // try {
        //     await add_keywords_to_user({
        //         variables: {
        //             keywords: match.keywords.slice(0, 20) //Limiting Payload To 20 Keywords
        //         }
        //     });
        // } catch (err) {
        //     console.error(err);
        // }

        if (!forJobPostingPage) {
            next()
        } else {
            setJustSaved(true);
        }
    }

    const noJobDescription = `Job Description for ${match.title} is not available at the moment. Please visit this link for more information: ${match.link}`;

    const saveTooltipText = auth.loggedIn()
        ? ""
        : "Sign up for Hiremize to save job postings";

    const autoApplyTooltipText = auth.loggedIn()
        ? "Auto Apply is not available for this job posting"
        : "Sign up for Hiremize to unlock auto-apply features";

    const searchConnectionsTooltipText = auth.loggedIn()
        ? ""
        : "Sign up for Hiremize to search for insider connections";


    const onClose = () => {
        setFindConnectionsModalVisible(false);
    };

    return (
        <div className="full-match-card">
            {!forJobPostingPage && (
                <button
                    className="not-interested"
                    onClick={handleNotInterested}
                >
                    Not Interested
                </button>
            )}
            <Row gutter={[24, 8]} className="match-card-row" style={{ display: 'flex' }}>
                <Col xs={24} md={10} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Row gutter={[0, 8]}>
                        <Col span={24}>
                            <JobPostingItem job={match} forMatcher={true} />
                        </Col>
                    </Row>
                    {match.keywords && match.keywords.length > 0 && (
                        <Row gutter={[0, 8]} style={{ marginTop: '5px' }}>
                            <Card style={{ marginTop: '5px', flex: '1', width: '100%' }}>
                                <KeywordsScroll keywords={match.keywords} />
                            </Card>
                        </Row>
                    )}
                    <Row gutter={[0, 8]} style={{ marginTop: '5px' }}>
                        <Col span={24}>
                            <JobPostingDetailsCard jobPosting={match} />
                        </Col>
                    </Row>
                    <Row gutter={[0, 8]} style={{ marginTop: '15px' }}>
                        <Col span={24}>
                            <Card
                                style={{
                                    position: 'relative',
                                    backgroundImage: 'url(https://media.istockphoto.com/id/1026485104/vector/community-network-and-social-icon.jpg?s=612x612&w=0&k=20&c=Kb_X-ZvcQYDmOtzoKtUDpIszcUWpLyDihCKzXJ1Hy-U=)',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    color: 'white',
                                    // textShadow: '1px 1px 2px rgba(0, 0, 0, 0.8)',
                                    overflow: 'hidden',
                                }}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(44, 164, 204, 0.9)', // Adjust the opacity as needed
                                        zIndex: 1,
                                    }}
                                ></div>
                                <div style={{ position: 'relative', zIndex: 2 }}>
                                    <h4 style={{ marginBottom: '10px' }}>Find connections at {match.company}</h4>
                                    <p style={{ marginBottom: '20px', fontSize: '18px' }}>Connect with company insiders to seek referrals and enhance your chances of securing the job.</p>
                                    <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                                        <Tooltip title={searchConnectionsTooltipText}>
                                            <Button
                                                disabled={!auth.loggedIn()}
                                                type="primary"
                                                className="button-color-premium"
                                                onClick={() => setFindConnectionsModalVisible(true)}
                                            >
                                                Search For Company Contacts
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} md={14} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    {/* Job Description */}
                    <Card title="Job Description" style={{ flex: '1', maxHeight: '70vh', overflowY: 'auto' }}>
                        <Paragraph style={{ whiteSpace: 'pre-wrap' }}>
                            {match.description ? match.description : noJobDescription}
                        </Paragraph>
                    </Card>
                    <div className="button-container">
                        { !justSaved ? (
                            <>
                                <Tooltip title={saveTooltipText}>
                                    <Button disabled={!auth.loggedIn() || justSaved} type="primary" className="button-color" onClick={handleSave}>
                                        {justSaved ? 'Saved!' : 'Save'}
                                    </Button>
                                </Tooltip>
                                {match.internal ? (
                                    <Button type="primary" className="button-color-premium" onClick={() => setApplyModalVisible(!applyModalVisible)} >Auto Apply</Button>
                                ) : (
                                    <Tooltip title={autoApplyTooltipText}>
                                        <div>
                                            <Button disabled={!match.internal} type="primary" className="button-color-premium">Auto Apply</Button>
                                        </div>
                                    </Tooltip>
                                )}
                            </>
                        ) : (
                            <div>
                                <p>Saved!</p>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
            {/* <FindConnectionsModal visible={findConnectionsModalVisible} setVisible={setFindConnectionsModalVisible} company={match.company} /> */}
            {findConnectionsModalVisible && <MessageModal onClose={onClose} messageType={"Network"} viewFindConnections={true} findConnectionsParam={match.company} />}
            {applyModalVisible && (
                <ApplyModal
                    visible={applyModalVisible}
                    onClose={() => setApplyModalVisible(false)}
                    job={match}
                    setJustSaved={setJustSaved}
                    notLoggedIn={auth.loggedIn()}
                />
            )}
        </div>
    );
};

export default JobPostingsMatchCard;